import { Carousel, Divider, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BankAccounts, useBankaccountGetAll } from '../../../../hooks/api/bankAccount';
import { BankAccountsButton } from '../../Accounts/BankAccount/Form/ModalForm';
import BankAccountsProvider from '../../Accounts/BankAccount/Form/context';

import styles from '../../Accounts/index.module.scss';

export default function AccountsOverview(): React.ReactNode | null {
  const bankAccount = useBankaccountGetAll();
  const [bankAccountData, setBankAccountData] = useState<BankAccounts[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeModalId, setActiveModalId] = useState<string>('');
  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
    setActiveModalId('');
  };

  const handleClick = (id: string) => {
    setActiveModalId(id);
    setModalOpen(true);
  };

  const updateTab = () => {
    bankAccount.fetch().then(() => {
      setBankAccountData(bankAccount.data?.bankAccounts || []);
    });
  };

  useEffect(() => {
    if (bankAccount.data) {
      setBankAccountData(bankAccount.data?.bankAccounts);
    }
  }, [bankAccount.data]);
  const slidesToShow = Math.min(bankAccountData.length, 4);
  const settings = {
    dots: false,
    infinite: false,
    // speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true,
    responsive: [
      {
        breakpoint: 1390,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      {bankAccountData.length < 4
        ? (
          <div style={{ display: 'flex', gap: '30px' }}>
            {bankAccountData
              ? (bankAccountData.map((item) => (
                <div key={item.id}>
                  <div style={{ marginTop: 16, display: 'flex' }} className={styles.card}>
                    <div>
                      <Typography.Title level={4} className={styles.title}>
                        <img
                          src={item.bank.logoUrl ? item.bank.logoUrl : '/media/Card_image.svg'}
                          alt=""
                          width={32}
                          style={{ marginRight: 8 }}
                        />
                        {item.name}
                      </Typography.Title>
                      <div className={styles.title}>
                        <strong>IBAN:</strong>
                        <strong>{item.iban.replace(/\s+/g, '').match(/.{1,4}/g)?.join(' ')}</strong>
                      </div>
                      <div style={{ color: '#000000A6', textAlign: 'left' }}>
                        {`${item.bank.address || ''} ${item.bank.phoneNumber || ''}`}
                      </div>
                    </div>
                    <div>
                      <Divider style={{ margin: '10px 0' }} />
                      <div className={styles.cardFooter}>
                        <div className={styles.title}>
                          <strong>{item.currency}</strong>
                          <strong>
                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                              .format(item.balance ?? 0)
                              .replace(/,/g, "'")}
                          </strong>
                        </div>
                        <BankAccountsProvider>
                          <BankAccountsButton
                            title="Details"
                            id={activeModalId}
                            text="Details"
                            updateTab={updateTab}
                            handleClick={() => handleClick(item.id)}
                            handleCloseModal={handleCloseModal}
                            isModalOpen={activeModalId === item.id}
                          />
                        </BankAccountsProvider>
                      </div>
                    </div>
                  </div>
                </div>
              )))
              : null}
          </div>
        )
        : (
          <Carousel arrows {...settings} className="custom-carousel">
            {bankAccountData
              ? (bankAccountData.map((item) => (
                <div key={item.id}>
                  <div style={{ marginTop: 16, display: 'flex' }} className={styles.card}>
                    <div>
                      <Typography.Title level={4} className={styles.title}>
                        <img
                          src={item.bank.logoUrl ? item.bank.logoUrl : '/media/Card_image.svg'}
                          alt=""
                          width={32}
                          style={{ marginRight: 8 }}
                        />
                        {item.name}
                      </Typography.Title>
                      <div className={styles.title}>
                        <strong>IBAN:</strong>
                        <strong>{item.iban.replace(/\s+/g, '').match(/.{1,4}/g)?.join(' ')}</strong>
                      </div>
                      <div style={{ color: '#000000A6', textAlign: 'left' }}>
                        {`${item.bank.address || ''} ${item.bank.phoneNumber || ''}`}
                      </div>
                    </div>
                    <div>
                      <Divider style={{ margin: '10px 0' }} />
                      <div className={styles.cardFooter}>
                        <div className={styles.title}>
                          <strong>{item.currency}</strong>
                          <strong>
                            {Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                              .format(item.balance ?? 0)
                              .replace(/,/g, "'")}
                          </strong>
                        </div>
                        <BankAccountsProvider>
                          <BankAccountsButton
                            title="Details"
                            id={activeModalId}
                            text="Details"
                            updateTab={updateTab}
                            handleClick={() => handleClick(item.id)}
                            handleCloseModal={handleCloseModal}
                            isModalOpen={activeModalId === item.id}
                          />
                        </BankAccountsProvider>
                      </div>
                    </div>
                  </div>
                </div>
              )))
              : null}
          </Carousel>
        )}
    </div>
  );
}
