import { Dayjs } from 'dayjs';
import {
  DefaultFetchError,
  FetchCreate, FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from '../fetch';

interface BankAccountsGetParams extends PagingParams {
  orderByColumn?: 'name';
}

export interface BankAccountseData {
  data: BankAccounts[];
}

export interface BankAccountsResponse {
  bankAccounts: BankAccounts[];
}

export interface BankAccounts extends BankAccountsParam {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export interface BankAccountsParam {
  name: string;
  iban: string;
  balance?: number;
  accountType: AccountTypeEnum | '';
  currency: string;
  note: string | '';
  accountingStartDate: string | Dayjs,
  hasTransactions?: boolean,
  bank: {
    logoUrl: string;
    name: string;
    address: string;
    phoneNumber: string;
  };
}

export enum AccountTypeEnum {
  private = 'Private account',
  savings = 'Savings account',
  rental = 'Rental deposit account'
}
export enum CurrencyEnum {
  CHF = 'CHF',
  EUR = 'EUR',
  USD = 'USD'
}

export const useBankAccountsCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, BankAccountsParam> => (
  useFetchCreate('bank-accounts')
);

export const useBankaccountGetAll = (): FetchGet<BankAccountsResponse, DefaultFetchError> => (
  useFetchGet('bank-accounts/all')
);

export const useBankaccountGet = <DD = BankAccountseData>(
  decorateData?: (data: PagingDataResponse<BankAccounts>) => DD,
): FetchGet<
    PagingDataResponse<BankAccounts>,
    BankAccountsGetParams,
    DefaultFetchError,
    DD
  > => useFetchGet(
    'bank-accounts',
    { autoStart: false, startStateLoading: false, decorateData },
  );

export const useAccountstGetAll = <DD = { value: string; label: string }[]>(
  decorateData: (data: { name: string; id: string }[]) =>
    DD = (data) => data.map((item) => ({ value: item.id, label: item.name })) as DD,
  autoStart = true,
): FetchGet<
  { data: { name: string; id: string }[]; meta: any },
  undefined,
  DefaultFetchError,
  DD
> => useFetchGet(
    'bank-accounts',
    {
      autoStart,
      startStateLoading: autoStart,
      decorateData: (response) => {
        if (Array.isArray(response.data)) {
          return decorateData(response.data);
        }
        console.error('Invalid data format:', response);

        return [] as DD;
      },
    },
  );

export const useBankaccountGetById = <DD = BankAccounts>(
  id?: string,
  decorateData?: (data: BankAccounts) => DD,
): FetchGetId<
      BankAccounts,
      DefaultFetchError,
      unknown,
      DD
    > => useFetchGetId(
    'bank-accounts',
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export const useBankAccountUpdate = (id?: string): FetchUpdate<
  FetchSuccess, DefaultFetchError, BankAccountsParam> => (
  useFetchUpdate('bank-accounts', id)
);

export const useBankAccountDelete = (id?: string): FetchDelete<
  FetchSuccess, DefaultFetchError
> => (
  useFetchDelete('bank-accounts', id)
);
