import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button, Form, Form as AntdForm, Input,
  Alert,
} from 'antd';
import { connect } from 'react-redux';
import {
  signUpTwoFA as signUpTwoFAAction, SignUpTwoFAAction,
  signUpResendEmail as signUpResendEmailAction,
  SignUpResendEmailAction,
} from '@triare/auth-redux/dist/saga/auth/signUp';
import { Action } from '@triare/auth-redux';
import Wrapper from '../../Wrapper';
import { createRulesForAntd, validationSignUpChangeEmail } from '../../../../../utils/validations';
import { useAuth } from '../../../../../store/auth';
import Submit from '../../../../Common/Form/Button/Submit';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationSignUpChangeEmail);

interface InformationProps {
  signUpResendEmail: (payload: SignUpResendEmailAction) => Action;
  signUpTwoFA: (payload: SignUpTwoFAAction) => Action;
}

function Information({
  signUpResendEmail, signUpTwoFA,
}: InformationProps): React.ReactNode {
  const {
    signUp: {
      data, loading, response, error, resendEmail,
    },
  } = useAuth();
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const [resend, setResend] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading && response && !error) {
      setState(false);
    }
  }, [loading, response, error]);

  return (
    <Wrapper>
      <div className={styles.content}>
        <h1>
          Verify your email address
        </h1>
        {response
          ? (
            <Alert
              style={{ marginBottom: '8px' }}
              type="success"
              message="Verification link sent successfully"
            />
          )
          : null}
        <div style={{ marginBottom: '4px' }}>
          To continue, please click the verification link in the email we sent to
          {' '}
          {data?.email}
          .
        </div>
        <div style={{ marginBottom: '8px' }}>
          If you don&apos;t see this email in your inbox, you may need to check your spam folder.
        </div>
        {/* <Button type="primary" onClick={() => navigate('/')}>
          Go to home page
        </Button>

        <br /> */}

        <Button
          // type="primary"
          size="large"
          loading={resendEmail.loading}
          onClick={() => {
            if (data && data.email) {
              signUpTwoFA(data);
            }
            setResend(true);
          }}
        >
          Resend verification email
        </Button>

        {state ? (
          <Form<{ email: string }>
            autoComplete="off"
            layout="vertical"
            onFinish={({ email }) => {
              if (data) {
                signUpTwoFA({
                  ...data,
                  email,
                });
              }
            }}
          >
            <AntdForm.Item
              name="email"
              label={`${t('email')}`}
              rules={[validationRules]}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={t('email') || 'Email'}
                size="large"
                readOnly
                onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
              />
            </AntdForm.Item>

            <Submit
              loading={loading}
              size="large"
            >
              Update email
            </Submit>
          </Form>
        ) : (
          <Button
            size="large"
            type="link"
            loading={resendEmail.loading}
            onClick={() => setState(true)}
          >
            Change my email
          </Button>
        )}
      </div>
    </Wrapper>
  );
}

export default connect(null, {
  signUpResendEmail: signUpResendEmailAction,
  signUpTwoFA: signUpTwoFAAction,
})(Information);
