import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Select } from 'antd';
import NetAssets from './NetAssets';
import { enumToOptionsArray } from '../../../utils';
import { CurrencyEnum } from '../../../hooks/api/bankAccount';
import AccountsOverview from './AccountsOverview';
import IncomeExpenses from './IncomeExpenses';

import styles from './index.module.scss';
import Savings from './Savings';

export default function OverviewContent(): React.ReactNode | null {
  const { t } = useTranslation();
  const [currencyOption, setCurrencyOption] = useState('CHF');

  return (
    <div style={{ padding: '8px 90px' }} className={styles.overviewContent}>
      <div className={styles.headerOwerview}>
        <div className={styles.title}>
          <h2>{dayjs().format('MMMM D, YYYY')}</h2>
          <span style={{ color: '#00000073' }}>
            &quot;If you can&apos;t buy it twice, you can&apos;t afford it.&quot; - Jay Z
          </span>
        </div>
        <div>
          <Select
            options={enumToOptionsArray(CurrencyEnum)}
            defaultValue={CurrencyEnum.CHF}
            onChange={(value) => setCurrencyOption(value)}
          />
        </div>
      </div>
      <AccountsOverview />
      <NetAssets currency={currencyOption} />
      <IncomeExpenses currency={currencyOption} />
      <Savings currency={currencyOption} />
    </div>
  );
}
