import React, { useState } from 'react';
import {
  Tabs,
  Divider,
} from 'antd';
import {
  IconAccTransfer,
  IconCategories,
  IconRegulate,
  IconTansactionsLiist,
} from '../../Common/Icon';
import TransactionListContent from './TransactionList';

const { TabPane } = Tabs;

export default function TransactionList(): React.ReactNode | null {
  const [banks, setBanks] = useState(0);

  return (
    <div style={{ padding: '8px 90px' }}>
      <Tabs defaultActiveKey="1" className="custom-tabs">
        <TabPane
          tab={(
            <span className="tabInfo">
              <div className="tabIco">
                <IconTansactionsLiist />
              </div>
              {' '}
              Transaction list
            </span>
                  )}
          key="1"
        >
          <h2>Transaction</h2>
          <Divider />
          <TransactionListContent />
        </TabPane>
        <TabPane
          key="2"
          tab={(
            <span className="tabInfo">
              <div className="tabIco">
                <IconRegulate />
              </div>
              {' '}
              Regulate
            </span>
                  )}
        />
        <TabPane
          key="3"
          tab={(
            <span className="tabInfo">
              <div className="tabIco">
                <IconCategories />
              </div>
              {' '}
              Categories
            </span>
                  )}
        />
        {/* <TabPane
          key="4"
          tab={(
            <span className="tabInfo">
              <div className="tabIco">
                <IconRecuring />
              </div>
              {' '}
              Recurring transactions
            </span>
                  )}
        /> */}
        <TabPane
          key="5"
          tab={(
            <span className="tabInfo">
              <div className="tabIco">
                <IconAccTransfer />
              </div>
              {' '}
              Account transfer
            </span>
                  )}
        />
      </Tabs>
    </div>
  );
}
