import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaConfig } from '@ant-design/charts';
import {
  DatePicker, Radio, Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import {
  DashboardGraphName, DashboardResponse, Months, useDashboardData,
} from '../../../../hooks/api/dashboards';
import NoData from '../../../Common/NoData';
import styles from './index.module.scss';

interface NetAssetsAreaProps{
  optionType: string;
  dateRange: [string, string]
  currency: string;
}
interface NetAssetsProps {
  currency: string;
}

const { RangePicker } = DatePicker;

function NetAssetsArea({ optionType, dateRange, currency }: NetAssetsAreaProps): React.ReactNode | null {
  const { open, contextHolder } = useSimpleModal();
  const dashboardData = useDashboardData();
  const [netAssetsData, setNetAssetsData] = useState<DashboardResponse>();
  const { data, loading, error } = dashboardData;

  useEffect(() => {
    if (!loading && !error && data) {
      setNetAssetsData({
        ...data,
        data: dataRefactor(data),
      });
    }
  }, [data, loading, error]);
  const fetch = async () => dashboardData.fetch(
    undefined,
    `${DashboardGraphName.NET_ASSETS}/${optionType}/${currency}/${dateRange[0]}/${dateRange[1]}`,
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const dataRefactor = (data: DashboardResponse) => {
    const newData = data.data.map((item) => ({
      ...item,
      amount: Number(item.amount),
      date: optionType === 'monthly'
        ? Months[Object.keys(Months)[Number(item.date) - 1] as keyof typeof Months]
        : item.date,
    }));

    return newData;
  };

  useEffect(() => {
    if ((dateRange[0] !== '' && dateRange[1] !== '')) {
      fetch();
      setNetAssetsData(data);
    }
  }, [dateRange]);
  useEffect(() => {
    if (currency !== 'CHF') {
      fetch();
      setNetAssetsData(data);
    }
  }, [currency]);

  const isEmptyState = React.useMemo(() => {
    if (!netAssetsData?.data?.length) return true;
    const nonZeroAmounts = netAssetsData.data.filter((item) => item.amount !== 0);

    return nonZeroAmounts.length === 0 || nonZeroAmounts.length === 1;
  }, [netAssetsData]);

  const config: AreaConfig = React.useMemo(() => ({
    data: netAssetsData?.data || [],
    xField: 'date',
    yField: 'amount',
    style: {
      fill: 'none',
    },
    interaction: {
      tooltip: {
        render: (e: any, { title, items }: any) => {
          const list = items
            .filter((item: any, index: number, self: any[]) => self.findIndex((i) => i.value === item.value) === index);

          return (
            <div key={title}>
              {/* <h4>{title}</h4> */}
              {list.map((item: any) => {
                const { name, value, color } = item;

                return (
                  // eslint-disable-next-line react/jsx-key
                  <div>
                    <div style={{
                      margin: 0, display: 'flex', gap: '5px',
                    }}
                    >
                      <span>{name}</span>
                      {/* <span>{value}</span> */}
                      <span>
                        {`${Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(value).replace(/,/g, "'")} ${currency}`}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        },
      },
    },

    axis: {
      y: {
        type: 'linear',
        labelFormatter: (value: number) => `${currency} ${Intl.NumberFormat('en-US')
          .format(value)
          .replace(/,/g, "'")}`,
      },
      x: optionType === 'monthly' ? {
        type: 'timeCat',
        tickCount: 12,
        tickInterval: '1 month',
      } : {
        type: 'time',
        tickCount: netAssetsData?.data?.length ? netAssetsData.data.length - 1 : 0,
        tickInterval: 1,
        label: {
          formatter: (text: string) => {
            const date = new Date(text);

            return `${date.getFullYear()}`;
          },
        },
      },
    },
    area: {
      shape: 'smooth',
      style: {
        fill: 'linear-gradient(-90deg, white 0%, #597EF7 100%)',
      },
    },
    smooth: true,
  }), [optionType, netAssetsData, dateRange, currency]);

  return (
    <>
      {isEmptyState ? (
        <div style={{
          height: '380px', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <NoData
            title="Not enough data"
            description={
            optionType === 'monthly'
              ? 'Use the Finatium at least for 2 months to overview monthly net assets'
              : 'Use the Finatium at least for 2 years to overview yearly net assets'
          }
          />
        </div>
      ) : (
        <Area
          {...config}
        />
      )}
      {contextHolder}
    </>
  );
}

const options = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];

export default function NetAssets({ currency }: NetAssetsProps): React.ReactNode | null {
  const { t } = useTranslation();
  const [optionType, setOptionType] = useState('monthly');
  const [dateRange, setDateRange] = useState<[string, string]>(optionType === 'monthly'
    ? [
      dayjs().subtract(1, 'months').format('YYYY-MM'),
      dayjs().format('YYYY-MM'),
    ]
    : [
      dayjs().subtract(1, 'year').format('YYYY'),
      dayjs().format('YYYY'),
    ]);

  useEffect(() => {
    if (optionType === 'monthly') {
      setDateRange([
        dayjs().subtract(1, 'months').format('YYYY-MM'),
        dayjs().format('YYYY-MM'),
      ]);
    } else {
      setDateRange([
        dayjs().subtract(1, 'year').format('YYYY'),
        dayjs().format('YYYY'),
      ]);
    }
  }, [optionType]);
  // useEffect(() => {
  //   if (optionType === 'monthly') {
  //     setDateRange([
  //       dayjs(dateRange[0], 'YYYY').startOf('year').format('YYYY-MM'),
  //       dayjs(dateRange[1], 'YYYY').endOf('year').format('YYYY-MM'),
  //     ]);
  //   } else {
  //     setDateRange([
  //       dayjs(dateRange[0], 'YYYY-MM').startOf('month').format('YYYY'),
  //       dayjs(dateRange[1], 'YYYY-MM').endOf('month').format('YYYY'),
  //     ]);
  //   }
  // }, [optionType]);

  return (
    <div className="iner-wrapper">
      <div className={styles.itemsHeader}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <h2>
            Net assets
          </h2>
          <Tooltip title="This is the total net assets of the company.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <RangePicker
            picker={optionType === 'monthly' ? 'month' : 'year'}
            defaultValue={[
              dayjs(dateRange[0]),
              dayjs(dateRange[1]),
            ]}
            format={optionType === 'monthly' ? 'MMM YYYY' : 'YYYY'}
            onChange={(dates) => {
              if (optionType === 'monthly') {
                const formattedDates: [string, string] = dates
                  ? [
                    dates[0]?.format('YYYY-MM') || '',
                    dates[1]?.format('YYYY-MM') || '',
                  ]
                  : ['', ''];

                setDateRange(formattedDates);
              } else {
                const formattedDates: [string, string] = dates
                  ? [
                    dates[0]?.format('YYYY') || '',
                    dates[1]?.format('YYYY') || '',
                  ]
                  : ['', ''];

                setDateRange(formattedDates);
              }
            }}
            disabledDate={(current) => {
              if (optionType === 'yearly') {
                const nextYear = dayjs().startOf('year');

                return current && current.isAfter(nextYear, 'year');
              }

              return false; // Для monthly не блокируем даты
            }}
          />
          <Radio.Group
            // block
            options={options}
            defaultValue="monthly"
            optionType="button"
            buttonStyle="solid"
            onChange={(value) => setOptionType(value.target.value)}
          />
        </div>
      </div>
      <NetAssetsArea optionType={optionType} dateRange={dateRange} currency={currency} />
    </div>
  );
}
