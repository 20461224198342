import React, { useState } from 'react';
import {
  Tabs,
  Divider,
} from 'antd';
import {
  Icon3APilar, IconBank, IconCash, IconCrypto, IconPension,
  IconPhysicalAssets,
  IconPortfolio,
  IconProperty,
} from '../../Common/Icon';
import BankAccount from './BankAccount';
import BankAccountsProvider from './BankAccount/Form/context';

export default function AccountsList(): React.ReactNode | null {
  const [banks, setBanks] = useState(0);

  const items = [
    {
      key: '1',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <IconBank />
          </div>
          {' '}
          Bank (
          {banks}
          )
        </span>
      ),
      children: (
        <>
          <Divider />
          <BankAccountsProvider>
            <BankAccount setBanks={setBanks} />
          </BankAccountsProvider>
        </>
      ),
    },
    {
      key: '2',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <IconCash />
          </div>
          {' '}
          Cash (0)
        </span>
      ),
      children: null, // Можно добавить содержимое вкладки
    },
    {
      key: '3',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <IconPension />
          </div>
          {' '}
          Pension (0)
        </span>
      ),
      children: null,
    },
    {
      key: '4',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <Icon3APilar />
          </div>
          {' '}
          3A Pillar (0)
        </span>
      ),
      children: null,
    },
    {
      key: '5',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <IconProperty />
          </div>
          {' '}
          Property (0)
        </span>
      ),
      children: null,
    },
    {
      key: '6',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <IconPortfolio />
          </div>
          {' '}
          Portfolio (0)
        </span>
      ),
      children: null,
    },
    {
      key: '7',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <IconPhysicalAssets />
          </div>
          {' '}
          Physical assets (0)
        </span>
      ),
      children: null,
    },
    {
      key: '8',
      label: (
        <span className="tabInfo">
          <div className="tabIco">
            <IconCrypto />
          </div>
          {' '}
          Crypto (0)
        </span>
      ),
      children: null,
    },
  ];

  return (
    <div style={{ padding: '8px 90px' }}>
      <Tabs defaultActiveKey="1" className="custom-tabs" items={items} />
    </div>
  );
}
