import React, { useEffect, useState } from 'react';
import { DualAxes } from '@ant-design/plots';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DatePicker, Radio, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from '../NetAssets/index.module.scss';

interface NetAssetsAreaProps{
  optionType: string;
  dateRange: [string, string]
  currency: string;
}
interface NetAssetsProps {
  currency: string;
}

const { RangePicker } = DatePicker;

function SavingsDualAxes() {
  const data = [
    {
      Month: 'Jan',
      Savings: 2,
      Total: 2,
    },
    {
      Month: 'Feb',
      Savings: 4.9,
      Total: 2.2,
    },
    {
      Month: 'Mar',
      Savings: 7,
      Total: 3.3,
    },
    {
      Month: 'Apr',
      Savings: 23.2,
      Expence: 0,
      Total: 4.5,
    },
    {
      Month: 'May',
      Savings: 25.6,
      Total: 6.3,
      Expence: 0,
    },
    {
      Month: 'Jun',
      Savings: 76.7,
      Total: 10.2,
      Expence: 0,
    },
    {
      Month: 'Jul',
      Savings: 135.6,
      Expence: 0,
      Total: 20.3,
    },
    {
      Month: 'Aug',
      Savings: 162.2,
      Total: 23.4,
      Expence: 0,
    },
    {
      Month: 'Sep',
      Savings: 32.6,
      Total: 23,
      Expence: 0,
    },
    {
      Month: 'Oct',
      Savings: -20,
      Total: 16.5,
      Expence: -20,
    },
    {
      Month: 'Nov',
      Savings: 6.4,
      Expence: 0,
      Total: 12,
    },
    {
      Month: 'Dec',
      Savings: 3.3,
      Expence: 0,
      Total: 6.2,
    },
  ];

  const config = {
    data,
    xField: 'Month',
    scale: { y: { nice: false } },
    children: [
      {
        type: 'area',
        yField: 'Savings',
        shapeField: 'smooth',
        colorField: 'linear-gradient(-90deg, white 0%, #597EF7 100%)',
        scale: { y: { domainMax: 200 } },
        axis: {
          y: {
            // title: 'Temperature (°C)',
            style: { titleFill: '#EE6666' },
          },
        },
        legend: {
          color: {
            size: 72, autoWrap: true, maxRows: 3, cols: 6,
          },
        },
      },
      {
        type: 'interval',
        yField: 'Savings',
        colorField: '#95DE64',
        scale: { y: { domainMax: 200 } },
        style: { maxWidth: 70 },

        axis: {
          y: {
            position: 'right',
            // title: 'Evaporation (ml)',
            style: { titleFill: '#5470C6' },
          },
        },
      },
      {
        type: 'interval',
        yField: 'Expence',
        colorField: '#FF7875',
        scale: { y: { domainMax: 200 } },
        style: { maxWidth: 70 },

        axis: {
          y: false,
        },
      },
      // {
      //   type: 'line',
      //   yField: 'Precipitation',
      //   shapeField: 'smooth',
      //   colorField: '#91CC75',
      //   style: {
      //     lineWidth: 2,
      //     lineDash: [2, 2],
      //   },
      //   axis: {
      //     y: {
      //       position: 'right',
      //       title: 'Precipitation (ml)',
      //       style: { titleFill: '#91CC75' },
      //     },
      //   },
      // },
    ],
  };

  return <DualAxes {...config} />;
}

const options = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];

export default function Savings({ currency }: NetAssetsProps): React.ReactNode | null {
  const { t } = useTranslation();
  const [optionType, setOptionType] = useState('monthly');
  const [dateRange, setDateRange] = useState<[string, string]>(optionType === 'monthly'
    ? [
      dayjs().subtract(1, 'months').format('YYYY-MM'),
      dayjs().format('YYYY-MM'),
    ]
    : [
      dayjs().subtract(1, 'year').format('YYYY'),
      dayjs().format('YYYY'),
    ]);

  useEffect(() => {
    if (optionType === 'monthly') {
      setDateRange([
        dayjs().subtract(1, 'months').format('YYYY-MM'),
        dayjs().format('YYYY-MM'),
      ]);
    } else {
      setDateRange([
        dayjs().subtract(1, 'year').format('YYYY'),
        dayjs().format('YYYY'),
      ]);
    }
  }, [optionType]);

  return (
    <div className="iner-wrapper">
      <div className={styles.itemsHeader}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <h2>
            Savings
          </h2>
          <Tooltip title="This is the total net assets of the company.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <RangePicker
            picker={optionType === 'monthly' ? 'month' : 'year'}
            defaultValue={optionType === 'monthly' ? [
              dayjs().subtract(1, 'months').startOf('month'),
              dayjs().endOf('month'),
            ]
              : [
                dayjs().startOf('year'),
                dayjs().add(1, 'year').startOf('year'),
              ]}
            format={optionType === 'monthly' ? 'MMM YYYY' : 'YYYY'}
            onChange={(dates) => {
              if (optionType === 'monthly') {
                const formattedDates: [string, string] = dates
                  ? [
                    dates[0]?.format('YYYY-MM') || '',
                    dates[1]?.format('YYYY-MM') || '',
                  ]
                  : ['', ''];

                setDateRange(formattedDates);
              } else {
                const formattedDates: [string, string] = dates
                  ? [
                    dates[0]?.format('YYYY') || '',
                    dates[1]?.format('YYYY') || '',
                  ]
                  : ['', ''];

                setDateRange(formattedDates);
              }
            }}
          />
          <Radio.Group
            // block
            options={options}
            defaultValue="monthly"
            optionType="button"
            buttonStyle="solid"
            onChange={(value) => setOptionType(value.target.value)}
          />
        </div>
      </div>
      <SavingsDualAxes />
    </div>
  );
}
