import React from 'react';
import { config } from '@triare/auth-redux';
import Simple from '../../../../components/Layout/Simple';
import Welcome from '../../../../components/Pages/Auth/WelcomePage';

const { displayName } = config;

export default function xWelcomePage(): React.ReactNode {
  document.title = `${displayName}: Welcome to Finatium`;

  return (
    <Simple>
      <Welcome />
    </Simple>
  );
}
