import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import AccountsList from '../../components/Pages/Accounts';

const { displayName } = config;

export default function Accounts(): React.ReactNode | null {
  document.title = `${displayName}: Accounts`;

  return (
    <Layout>
      <AccountsList />
    </Layout>
  );
}
