import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sankey, SankeyConfig } from '@ant-design/plots';
import { Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { enumToOptionsArray } from '../../../../utils';
import { IncomeExpensesData, useIncomeExpensesData } from '../../../../hooks/api/dashboards';

import styles from './index.module.scss';

interface IncomeExpensesAreaProps{
  optionType: string;
  currency: string;
}
interface IncomeExpensesProps {
  currency: string;
}

export enum TimeRange {
  CURRENT_MONTH = 'current_month',
  LAST_MONTH = 'last_month',
  LAST_3_MONTHS = 'last_3_months',
  LAST_6_MONTHS = 'last_6_months',
  LAST_YEAR = 'last_year',
  LAST_3_YEARS = 'last_3_years',
  ALL_TIME = 'all_time',
}
const TimeRangeLabels = {
  [TimeRange.CURRENT_MONTH]: 'Current month',
  [TimeRange.LAST_MONTH]: 'Last month',
  [TimeRange.LAST_3_MONTHS]: 'Last 3 months',
  [TimeRange.LAST_6_MONTHS]: 'Last 6 months',
  [TimeRange.LAST_YEAR]: 'Last year',
  [TimeRange.LAST_3_YEARS]: 'Last 3 years',
  [TimeRange.ALL_TIME]: 'All time',
};

function IncomeExpensesArea({ optionType, currency }: IncomeExpensesAreaProps): React.ReactNode | null {
  const incomeExpensesData = useIncomeExpensesData();
  const [incomeExpenses, setIncomeExpenses] = useState<IncomeExpensesData[]>();
  const { data, loading, error } = incomeExpensesData;

  const fetch = async () => incomeExpensesData.fetch(
    undefined,
    `${currency}/${optionType}`,
  );

  useEffect(() => {
    if (!loading && !error && data) {
      setIncomeExpenses(
        data.data,
      );
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (currency !== 'CHF') {
      fetch();
    }
    fetch();
  }, [currency]);
  useEffect(() => {
    if (optionType) {
      fetch();
      setIncomeExpenses(data?.data);
    }
  }, [optionType]);
  const dataNew = [
    {
      source: 'Wage XY AG',
      target: 'Total income',
      value: 600,
    },
    {
      source: 'Wage XY AG 1',
      target: 'Total income',
      value: 300,
    },
    {
      source: 'Total income',
      target: 'Fixed expense',
      value: 300,
    },
    {
      source: 'Total income',
      target: 'Flexible spending',
      value: 300,
    },
    {
      source: 'Fixed expense',
      target: 'Daycare',
      value: 100,
    },
    {
      source: 'Fixed expense',
      target: 'Insurance',
      value: 100,
    },
    {
      source: 'Fixed expense',
      target: 'Subscriptions',
      value: 20,
    },
    {
      source: 'Flexible spending',
      target: 'Gas',
      value: 60,
    },
    {
      source: 'Flexible spending',
      target: 'Sopping',
      value: 70,
    },
    {
      source: 'Total income',
      target: 'Savings',
      value: 300,
    },
    {
      source: 'Savings',
      target: '',
      value: 0,
    },
  ];

  const config: SankeyConfig = {
    data: incomeExpenses || [],
    // data: dataNew,
    scale: { color: { range: ['#FADB14', '#13C2C2', '#2F54EB', '#EB2F96'] } },
    linkColorField: (d: { source: { key: string, name: string, }; }) => d.source.key,
    layout: {
      nodeWidth: 0.02, nodePadding: 0.09, nodeAlign: 'right',
    },
    label: {
      text: (d: any) => `${d.data.name} - Yes`, // Concatenate original label with your text
      position: 'inside',
      transform: [
        {
          type: 'contrastReverse',
        },
      ],
    },
    style: {
      labelFontSize: 14,
      linkFillOpacity: 0.4,
    },
  };

  return <Sankey {...config} />;
}

export default function IncomeExpenses({ currency }: IncomeExpensesProps): React.ReactNode | null {
  const { t } = useTranslation();
  const [optionType, setOptionType] = useState('current_month');

  return (
    <div className="iner-wrapper">
      <div className={styles.itemsHeader}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <h2>
            Overview of income and expenses
          </h2>
          <Tooltip title="This is the total net assets of the company.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Select
            defaultValue={TimeRange.CURRENT_MONTH}
            options={enumToOptionsArray(TimeRangeLabels)}
            style={{ width: '200px', textAlign: 'left' }}
            onChange={(option) => setOptionType(option)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '760px' }}>
          <IncomeExpensesArea optionType={optionType} currency={currency} />
        </div>
      </div>
    </div>
  );
}
