import React, { useEffect, useState } from 'react';
import { Layout as AntdLayout, Button, theme } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import Menu from './Menu';
import ReduxRedirect from '../Common/ReduxRedirect';

import styles from './index.module.scss';
import FooterContetnt from '../Common/FooterContetnt';

const {
  Header, Sider, Content, Footer,
} = AntdLayout;

interface LayoutProps {
  LeftMenu?: React.FC;
  leftMenu?: React.ReactNode;
  transparent?: boolean;
  disableLeftMenu?: boolean;
  children: React.ReactNode;
  collapsedByDefault?: boolean;
  minWidth?: boolean;
}

let defaultStateCollapsed = false;

function Layout({
  disableLeftMenu = false,
  LeftMenu,
  leftMenu,
  transparent,
  children,
  collapsedByDefault = false,
  minWidth,
}: LayoutProps): React.ReactNode {
  const [collapsed, setCollapsed] = useState(defaultStateCollapsed);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  const disableMenu = disableLeftMenu || (!LeftMenu && !leftMenu);

  useEffect(() => {
    defaultStateCollapsed = collapsed;
  }, [collapsed]);

  useEffect(() => {
    if ((isTablet && !disableMenu) || collapsedByDefault) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isTablet, disableMenu]);

  return (
    <>
      <ReduxRedirect />
      <AntdLayout hasSider className={styles.layout}>
        {disableMenu ? null : (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={clsx(styles.slider, { [styles.absolute]: isTablet && !disableMenu })}
            width={224}
            collapsedWidth={48}
          >
            <NavLink to="/" className={clsx(styles.logo, { [styles.collapsed]: collapsed })}>
              {collapsed ? <img src="/logo.png" className={styles.logoSvg} alt="Finatium" /> : null}
              {/* <LogoMini className={styles.logoSvg} /> */}

              {!collapsed ? <img src="/logo.png" className={styles.logoSvg} alt="Finatium" /> : null}
              {/* <Logo className={styles.logoSvg} /> */}
            </NavLink>

            <div className={clsx(styles.filter, { [styles.collapsed]: collapsed })}>
              {LeftMenu ? <LeftMenu /> : leftMenu}
            </div>

            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              className={clsx(styles.btn, { [styles.collapsed]: collapsed })}
            />
          </Sider>
        )}
        <AntdLayout className={clsx(styles.wrapper, { [styles.tablet]: isTablet && !disableMenu })}>
          <Header
            style={{ background: colorBgContainer }}
            className={clsx(styles.header, { [styles.headerLogo]: disableMenu })}
          >
            {disableMenu ? (
              <NavLink to="/overview" className={clsx(styles.logo, { [styles.tablet]: isTablet })}>
                {collapsed ? <img src="/logo.png" className={styles.logoSvg} alt="Finatium" /> : null}
                {/* <LogoMini className={styles.logoSvg} /> */}

                {!collapsed ? <img src="/media/finatium_logo.svg" className={styles.logoSvg} alt="Finatium" /> : null}
                {/* <Logo className={styles.logoSvg} /> */}
              </NavLink>
            ) : null}

            <Menu />
          </Header>
          <div className={clsx(styles.scroll, { [styles.collapsed]: collapsed })}>
            <Content
              className={clsx(
                styles.content,
                { [styles.transparent]: transparent },
                { [styles.minWidth]: isTablet && minWidth },
              )}
              id="layout-content"
            >
              {children}
            </Content>
            <FooterContetnt />
          </div>
        </AntdLayout>
      </AntdLayout>
    </>
  );
}

export default Layout;
