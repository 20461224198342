import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Wrapper from '../Wrapper';
import { createRulesForAntd, validationNewPassword } from '../../../../utils/validations';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationNewPassword);

export default function Welcome(): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <h1 style={{ textAlign: 'center', margin: '0' }}>
        Welcome to Finatium!
        {' '}
        {/* eslint-disable-next-line no-nested-ternary */}
      </h1>
      <div className={styles.welcomePage}>
        <span>You have successfully completed the first step towards improving your financial situation.</span>
        <img src="/media/welcome-finatium.png" alt="welcome-logo" />
        <Button
          size="large"
          type="primary"
          onClick={() => navigate('/overview')}
        >
          Continue
        </Button>
      </div>
    </Wrapper>
  );
}
