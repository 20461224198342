import React from 'react';
import { config } from '@triare/auth-redux';

import { Divider } from 'antd';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';

const { displayName } = config;

export default function FooterContetnt(): React.ReactNode | null {
  document.title = `${displayName}: Home page`;

  return (
    <div className={styles.footerContent}>
      <div className={styles.info}>
        <div className={styles.items}>
          <NavLink to="/overview">
            <img src="/media/finatium_logo_footer.svg" alt="logo" />
          </NavLink>
          <a href="#">Privacy Policy</a>
          <a href="#">Terms and Conditions</a>
          <a href="#">FAQ</a>
          <a href="#">Support</a>
        </div>
        <div className={styles.social}>
          <a href="https://www.linkedin.com/company/finatium" target="_blank" rel="noopener noreferrer">
            <img src="/media/social/linkedIn.svg" alt="linkedIn" />
          </a>
          <a href="https://x.com/finatium" target="_blank" rel="noopener noreferrer">
            <img src="/media/social/twitter.svg" alt="twitter" />
          </a>
          <a href="https://www.youtube.com/@Finatium" target="_blank" rel="noopener noreferrer">
            <img src="/media/social/youtube.svg" alt="youtube" />
          </a>
          <a href="https://www.instagram.com/finatiumch" target="_blank" rel="noopener noreferrer">
            <img src="/media/social/instagram.svg" alt="instagram" />
          </a>
        </div>
      </div>
      <Divider style={{ borderColor: '#FFFFFF4D', margin: '16px 0' }} />
      <div className={styles.rights}>
        © 2024 FINATIUM. All rights reserved.
      </div>
    </div>
  );
}
