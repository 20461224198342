import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import clsx from 'clsx';
import TextArea from 'antd/es/input/TextArea';
import { Form, Input } from 'antd';
import {
  CheckOutlined, LeftOutlined, LoadingOutlined,
  PlusCircleOutlined, SearchOutlined, SendOutlined, SmileOutlined, UserAddOutlined, UsergroupAddOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { EmojiClickData } from 'emoji-picker-react';
import socketProvider from '../../../socket';
import { IconDockToRight } from '../Icon';
import Scroll from '../Scroll';
import { moduleName, User } from '../../../store/auth';
import { RootState } from '../../../store';
import {
  DeleteMessageResponse, IChat, Message, ReceiveReactionPayload,
  useAddChatMembers,
  useChatCreate,
  useChatIdMessages,
  useChatsGet,
  useDeleteChatMembers,
} from '../../../hooks/api/chats';
import Loading from '../Loading';
import { getChatItemFromStorage, setChatItemInStorage, sortChatsByLastMssgTime } from './storage-utils';
import ContactsList from './ContactsList';
import ContactRow from './ContactRow';
import { useMessageError } from '../../../hooks/common';
import { useChatsProvider } from '../../../context/chats';
import SiderRightAdditions from './SiderRightAdditions';
import ChatHead from './ChatHead';
import ChatMessage from './Message';
import { queryFilterParams } from '../../../utils';
import {
  getUserDisplayName,
  getViewMode,
  MOBILE_MAX_SIZE,
  TABLET_MAX_SIZE,
  getUpdateChats,
  getUpdateMessages,
  ViewMode,
} from './utils';
import ActionPreview from './ActionPreview';

import styles from './index.module.scss';
import TimeIndicator from './TimeIndicator';

interface FormItems {
  message: string;
  messageId: string;
}

interface ChatProps {
  user: User;
}

/** TODO- Whats do next with chats module?
 * - Add Search component handlers.
 1. For existing chats list - create own handlers.
 2. For 'Create Chat' you can use function from context - 'handleFetchUsers(_, { your_searchParams_here })')
 * - Компонент <Scroll> не додає кастомний скрол-бар, якщо його висота змінилась після його першого рендера.
 Наприклад після завантаження даних з API.
 * - Update chat display name for group-chats in .head block. show users list
 * - Додати тултіпчик, який буде показувати на який день зараз дивиться юзер, якщо він поскролив до старіших повідомлень
 * - Додати відображення онлайн статусу юзерів.  (events defined in 'interface ClientToServerEvents')
 * - додати індикатор 'typing...'
 * - додати "virtual-scroll" для відображення меседжів та можливо чатів.
 * - Check comment 'TODO change to 'firstName''
 * - Check tasks on the board :) */

function Chat({ user }: ChatProps): React.ReactNode | null {
  const ref = useRef<HTMLDivElement | null>(null);
  const [viewMode, setViewMode] = useState<ViewMode>(getViewMode(null));
  const isDesktop = viewMode === 'desktop';
  // const isTablet = viewMode === 'tablet';
  const isMobile = viewMode === 'mobile';
  const loading = false;
  const response = false;

  const messageInput = useRef<HTMLTextAreaElement>(null);

  const {
    selectedChatId, selectedMessageId, handleSelectMessage,
    chatsLoading, messagesLoading, handleChatsLoading, handleFetchUsers,
    openAdditions, handleOpenAdditions,
    messageActionState, handleMessageActionState,
  } = useChatsProvider();

  const [chats, setChats] = useState<IChat[]>([]);
  const [messages, setMessages] = useState<Record<string, Message[]>>({});
  const [_, setSearchParams] = useSearchParams();

  const chatsGet = useChatsGet();
  const chatCreate = useChatCreate();
  const addChatMembers = useAddChatMembers();
  const deleteChatMembers = useDeleteChatMembers();

  const messagesByChatId = useChatIdMessages();

  const fetchChatsList = () => {
    chatsGet.fetch().then((res) => {
      if (res?.length) {
        setChats(sortChatsByLastMssgTime(res));
      }
    });
  };

  const fetchMessagesById = (chatId: string) => {
    messagesByChatId.fetch(undefined, `${chatId}/messages`)
      .then((res) => {
        if (res?.length) {
          setMessages((prevState) => ({
            ...prevState,
            [chatId]: res,
          }));
        } else {
          setMessages((prevState) => ({
            ...prevState,
            [chatId]: [],
          }));
        }
      });
  };

  useEffect(() => {
    if (selectedChatId) {
      /** If there no state with messages in selected chat, or every message have chatId(means they from socket),
       * then we need to fetch all messages */
      if (!messages[selectedChatId]?.length || messages[selectedChatId]?.every((message) => message.chatId)) {
        fetchMessagesById(selectedChatId);
      }

      form.resetFields(['message']);

      /** If mobile view, then close menu with chats on select */
      if (viewMode === 'mobile') { setOpenList(false); }
    }
  }, [selectedChatId]);

  const currentChatObject = useMemo(() => (
    chats.find((chat) => chat.id === selectedChatId)
  ), [selectedChatId, chats]);

  /** Handling form submit and events */
  const handleSubmit = (values: { message: string; messageId: string; }) => {
    if (messageActionState.mode === 'edit') {
      return handleMessageUpdate(values);
    }
    if (messageActionState.mode === 'reply') {
      handleMessageActionState(null);

      return handleMessageCreate(values);
    }

    return handleMessageCreate(values);
  };

  const handleMessageCreate = ({ message }: FormItems) => {
    if (!selectedChatId || !socketProvider.socket) return;

    socketProvider.socket.emit('message', { message, chat: selectedChatId || '' });
    form.resetFields(['message']);
  };

  const handleMessageUpdate = ({ message, messageId }: FormItems) => {
    if (!selectedChatId || !socketProvider.socket) return;

    socketProvider.socket.emit('update-message', { message, id: messageId });
    form.resetFields(['message', 'messageId']);

    handleMessageActionState(null);
  };

  useEffect(() => {
    if (messageActionState.mode === 'edit' && messageActionState.message) {
      form.setFieldsValue({
        message: messageActionState.message.message,
        messageId: messageActionState.message.id,
      });
      messageInput.current?.focus();
    }
    if (!messageActionState.mode && !messageActionState.message) {
      form.resetFields(['message', 'messageId']);
    }
  }, [messageActionState.message, messageActionState.mode]);

  const handleChatCreate = async (members: string[]) => {
    await chatCreate.fetch({ members, single: members.length <= 1 })
      .then((res) => {
        if (res?.id) {
          setSearchParams({ id: res.id });
        }
      });
  };

  useEffect(() => {
    handleChatsLoading(chatCreate.loading);
  }, [chatCreate.loading]);
  useMessageError([chatsGet, chatCreate, messagesByChatId]);

  /** Form & Draft handling: \/ */
  const [form] = Form.useForm();
  const messageWatch = Form.useWatch('message', form);
  const [initialValues, setInitialValues] = useState({ message: '' });

  useEffect(() => {
    if (selectedChatId) {
      const initialChatValue = getChatItemFromStorage(selectedChatId);

      setInitialValues({ message: initialChatValue?.message || '' });
    }
  }, [selectedChatId]);
  useEffect(() => {
    if (selectedChatId) {
      const timeoutId = setTimeout(() => {
        setChatItemInStorage({ message: messageWatch }, selectedChatId);
      }, 700);

      return () => clearTimeout(timeoutId);
    }

    return undefined;
  }, [messageWatch]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  /** When new messages added to the messages list - scroll to bottom */
  const [scrollToTrigger, setScrollToTrigger] = useState<number>(0);

  useEffect(() => {
    setScrollToTrigger(Date.now());
  }, [messages[selectedChatId || '']?.length]);

  const [isConnected, setIsConnected] = useState(socketProvider.socket?.connected);

  useEffect(() => {
    /** Fetch chats list on the component initialization */
    fetchChatsList();

    if (socketProvider.socket) {
      const { socket } = socketProvider;

      if (!socket.connected) { socket.connect(); }
      if (socket?.connected) {
        console.log('if socket connected: ');
      }

      const onConnect = () => {
        setIsConnected(true);
        console.log('on connect in use effect:', socket?.id);
      };

      socket?.onAny((eventName, ...args) => console.log('eventname:', eventName, '| args:', args));

      const onDisconnect = () => {
        console.log('onDisconnect');
        setIsConnected(false);
        socket?.disconnect();
      };

      const onReceiveMessage = (message: Message) => {
        const mssgChatId = message.chatId;

        if (mssgChatId) {
          setMessages((prevMessages) => {
            const updatedMessages = getUpdateMessages({
              event: 'add', chatId: mssgChatId, message, prevMessages,
            });

            /** Updating current chat latest message, which displayed in the chats list */
            setChats((prevChats) => {
              const updatedChats = getUpdateChats({
                event: 'add', chatId: mssgChatId, message, messages: updatedMessages, prevChats,
              });

              return updatedChats;
            });

            return updatedMessages;
          });
        }

        // TODO Kinda can be removed, because added util function which does this
        /* if (mssgChatId) {
          /!** When user gets new message, update messages state, and push new message to the end of chat array *!/
          setMessages((prevState) => ({
            ...prevState,
            [mssgChatId]: [...(prevState[mssgChatId] || []), message],
          }));
          /!** On 'message' need to update chats list, and set latest message into current chat. *!/
          setChats((prevState) => {
            const oldState = prevState.filter(({ id }) => id !== mssgChatId);
            const currentChat = prevState.find(({ id }) => id === mssgChatId);

            if (currentChat) {
              return sortChatsByLastMssgTime([
                { ...currentChat, messages: [message] },
                ...oldState,
              ]);
            }

            return prevState;
          });
        } */
      };

      const onReceiveMessageUpdate = (message: Message) => {
        const mssgChatId = message.chat?.id; // TODO ask backend to leave only 1 type on create/upd

        if (mssgChatId) {
          setMessages((prevMessages) => {
            const updatedMessages = getUpdateMessages({
              event: 'update', chatId: mssgChatId, message, prevMessages,
            });

            /** Updating current chat latest message, which displayed in the chats list */
            setChats((prevChats) => {
              const updatedChats = getUpdateChats({
                event: 'update', chatId: mssgChatId, message, messages: updatedMessages, prevChats,
              });

              return updatedChats;
            });

            return updatedMessages;
          });
        }
      };

      const onReceiveMessageDelete = (res: DeleteMessageResponse) => {
        const mssgChatId = res.chat.id;
        const message = { id: res.id } as Message;

        if (mssgChatId) {
          setMessages((prevMessages) => {
            const updatedMessages = getUpdateMessages({
              event: 'delete', chatId: mssgChatId, message, prevMessages,
            });

            setChats((prevChats) => {
              const updatedChats = getUpdateChats({
                event: 'delete', chatId: mssgChatId, message, messages: updatedMessages, prevChats,
              });

              return updatedChats;
            });

            return updatedMessages;
          });
        }
      };

      const onReceiveChat = (chat: IChat) => {
        setChats((prevState) => [chat, ...prevState]);
      };

      const onReceiveReaction = (res: ReceiveReactionPayload) => {
        const mssgChatId = res.chatId;

        setMessages((prevState) => {
          const newState = ({
            ...prevState,
            [mssgChatId]: (prevState[mssgChatId] || [])?.map((item) => {
              if (item.id === res.message.id) {
                return ({
                  ...item,
                  reactions: [...(item.reactions || []), { reaction: res.reaction }],
                }) as Message;
              }

              return item;
            }),
          });

          return newState;
        });
      };

      socket.on('connect', onConnect);

      socket.on('receive-message', onReceiveMessage);
      socket.on('receive-update-message', onReceiveMessageUpdate);
      socket.on('receive-delete-message', onReceiveMessageDelete);
      socket.on('receive-chat', onReceiveChat);
      socket.on('receive-reaction', onReceiveReaction);

      socket.on('disconnect', onDisconnect);

      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        // onDisconnect();
      };
    }

    return () => {};
  }, []);

  const [openList, setOpenList] = useState(true);
  // const [openAdditions, setOpenAdditions] = useState(false);

  const [send, setSend] = useState(false);
  const [startChat, setStartChat] = useState(false);

  useEffect(() => {
    if (!openList) {
      setStartChat(false);
    }
  }, [openList]);

  useEffect(() => {
    if (send) {
      setTimeout(() => {
        setSend(false);
      }, 2500);
    }
  }, [send]);

  /** Handling screen resize */
  useEffect(() => {
    if (!ref.current) {
      return () => {
        // wait
      };
    }

    let id: NodeJS.Timeout;

    function resize() {
      if (id) {
        clearTimeout(id);
      }

      id = setTimeout(() => {
        setViewMode(getViewMode(ref.current));
      }, 16);
    }

    window.addEventListener('resize', resize, false);

    resize();

    return () => {
      window.removeEventListener('resize', resize);
      if (id) {
        clearTimeout(id);
      }
    };
  }, [ref.current]);

  /** Handling search */
  const [options, setOptions] = useState<{ value: string }[]>([]);

  // TODO check how this should work
  const [contactSearch, setContactSearch] = useState('');
  const handleSearch = (value: string) => {
    setOptions(
      !value ? [] : [{ value }, { value: value + value }, { value: value + value + value }],
    );
  };

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    console.log('handleKeyPress', ev);
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onSelect = (value: string) => {
    console.log('onSelect', value);
  };

  useEffect(() => {
    const callback = () => {
      if (startChat) { // If search on 'add contact screen'
        if (contactSearch.length >= 2 || contactSearch === '') {
          handleFetchUsers(
            [user.id],
            queryFilterParams({ fullName: contactSearch }),
          )
            .then((res) => {
              const newOptions = (res?.data || []).map((item) => ({
                value: getUserDisplayName(item),
              })) || [];

              setOptions(newOptions); // Set autocomplete options. Can be removed if dont use autoComplete
            });
        }
      } else {
        console.log('search in chats list instead of users list'); // TODO add search
      }
    };

    const timeout = setTimeout(callback, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [contactSearch]);

  // const [fetching, setFetching] = useState(false);
  // const [options, setOptions] = useState<ValueType[]>([]);
  // const fetchRef = useRef(0);

  // const debounceFetcher = useMemo(() => {
  //   const loadOptions = (value: string) => {
  //     fetchRef.current += 1;
  //     const fetchId = fetchRef.current;
  //     setOptions([]);
  //     setFetching(true);
  //
  //     fetchOptions(value).then((newOptions) => {
  //       if (fetchId !== fetchRef.current) {
  //         // for fetch callback order
  //         return;
  //       }
  //
  //       setOptions(newOptions);
  //       setFetching(false);
  //     });
  //   };
  //
  //   return debounce(loadOptions, debounceTimeout);
  // }, [fetchOptions, debounceTimeout]);

  const handleAddEmoji = (emoji: EmojiClickData) => {
    const prevVal = form.getFieldValue('message');

    form.setFieldValue('message', `${prevVal || ''}${emoji.emoji}`);
    if (!prevVal) {
      form.validateFields(['message']);
    }
  };

  /** Handling context-menu when scroll */
  useEffect(() => {
    const handleScrollOrTouch = () => {
      if (selectedMessageId !== '') {
        handleSelectMessage('');
      }
    };

    const messagesContainer = document.getElementById('messagesList');

    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScrollOrTouch);
      messagesContainer.addEventListener('touchmove', handleScrollOrTouch);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScrollOrTouch);
        messagesContainer.removeEventListener('touchmove', handleScrollOrTouch);
      }
    };
  }, [selectedMessageId]);

  return (
    <div>
      <button type="button" onClick={() => socketProvider.socket?.connect()}>Connect</button>
      <button type="button" onClick={() => socketProvider.socket?.disconnect()}>Disconnect</button>

      <div
        ref={ref}
        className={clsx(styles.chat, styles[viewMode])}
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          maxWidth: '100%',
          width: '100%',

          '--chat-panel-width-min': '280px',
          '--chat-panel-width-max': '360px',
          '--chat-tablet-size': `${TABLET_MAX_SIZE}px`,
          '--chat-mobile-size': `${MOBILE_MAX_SIZE}px`,
        }}
      >
        <div className={clsx(styles.list, { [styles.open]: openList })}>
          <div className={styles.head}>
            {startChat ? (
              <div className={styles.startChat}>
                <div
                  role="none"
                  className={styles.back}
                  onClick={() => setStartChat(false)}
                >
                  <LeftOutlined />
                </div>
                Start chat
              </div>
            ) : (
              <>
                <ContactRow user={user} variant="head" />

                <div
                  role="none"
                  className={styles.addChat}
                  onClick={() => setStartChat(true)}
                >
                  <UserAddOutlined />
                </div>
              </>
            )}

            {openList ? (
              <IconDockToRight
                className={styles.icon}
                onClick={() => setOpenList(false)}
              />
            ) : null}
          </div>

          <div
            className={styles.search}
            onClick={openList ? undefined : (e) => {
              e.currentTarget.querySelector('input')?.focus();
              setOpenList(true);
            }}
          >
            {/* <AutoComplete
              options={options}
              // onSelect={onSelect}
              // onSearch={handleSearch}
            > */}
            <Input
              size="large"
              placeholder="Search"
              prefix={<SearchOutlined />}
              onKeyPress={handleKeyPress}
              onChange={(e) => setContactSearch(e.target.value)}
            />
            {/* </AutoComplete> */}

            {startChat ? (
              <div className={styles.startGroup}>
                <UsergroupAddOutlined />
                Start group chat
              </div>
            ) : null}
          </div>

          {chatsGet.loading || chatsLoading ? (<Loading absolute />) : null}

          <Scroll
            wrapperProps={{
              className: styles.wrapper,
            }}
          >
            {startChat ? (
              <ContactsList
                onRow={(id) => {
                  /** When click on row, check if user already have chat with this contact. If yes - open it */
                  const foundChat = (chats || []).find((chat) => (
                    chat.single && chat.members?.find((member) => member.user?.id === id)));

                  if (foundChat) {
                    setSearchParams({ id: foundChat.id });
                    setStartChat(false);
                  } else {
                    handleChatCreate([id]).then(() => setStartChat(false));
                  }
                }}
                ignoreIds={[user?.id]}
              />
            ) : (
              chats.map(({
                id, members, messages: chatMessages, name,
              }) => {
                const chatLastMssg = chatMessages?.[0];
                const draftMssg = getChatItemFromStorage(id)?.message;

                return (
                  <ContactRow
                    key={id}
                    onClick={() => setSearchParams({ id })}
                    isRowActive={id === selectedChatId}
                  >
                    <div className={styles.messageGrid}>
                      <span className="clip">
                        {name || members
                          .filter((member) => member.user?.id !== user?.id)
                          .map((member) => getUserDisplayName(member.user))
                          .join(', ')}
                      </span>
                      {/** If chat have message, then add 3 more elements */}
                      {(chatLastMssg || draftMssg) && (
                      <>
                        <TimeIndicator message={chatLastMssg} className={styles.time} />
                        <span className={clsx(styles.messagePreview, 'clip')}>
                          {draftMssg ? (
                            <>
                              <span className={styles.draft}>
                                Draft:
                              </span>
                              {draftMssg}
                            </>
                          ) : chatLastMssg?.message}
                        </span>
                      </>
                      )}
                    </div>
                  </ContactRow>
                );
              })
            )}
          </Scroll>
        </div>

        {/** Middle content - Chat head, Messages & Form */}
        <div
          className={styles.messages}
          onClick={!isDesktop && openAdditions.open ? () => handleOpenAdditions({ open: false }) : undefined}
        >
          {messagesByChatId.loading || messagesLoading ? (<Loading absolute />) : null}

          {/** Header of the current chat */}
          <div className={styles.head} style={selectedChatId ? { borderBottom: '1px solid #ccc' } : undefined}>
            <ChatHead
              currentUser={user}
              chatData={currentChatObject}
              actionsProps={{
                openList,
                setOpenList,
                isMobile,
                isDesktop,
              }}
            />
          </div>

          {/** Current chat content - Messages */}
          {/* TODO add virtual-scroll? ask backend to add to each chat 'messagesCount' and render it */}
          <Scroll id="messagesList" className={styles.content} scrollToBottomTrigger={scrollToTrigger}>
            <div className={styles.scroll}>
              {(messages[selectedChatId || ''] || []).map((message) => (
                <ChatMessage
                  key={message.id}
                  messageItem={message}
                  user={user}
                  single={currentChatObject?.single}
                />
              ))}
            </div>
          </Scroll>

          {/** Footer - form */}
          <div className={styles.footer}>
            <div className={styles.contentRow}>
              <ActionPreview />

              <div className={styles.textarea}>
                <div className={styles.action}>
                  <PlusCircleOutlined />
                </div>
                <div
                  role="button"
                  aria-label="open-sidebar"
                  tabIndex={-1}
                  className={styles.action}
                  onClick={() => handleOpenAdditions({ open: true, mode: 'emoji' })}
                >
                  <SmileOutlined />
                </div>
                {/* TODO remove validation and add 'if messageWatch' in handleMessageCreate? */}
                <Form
                  name="chat-form"
                  form={form}
                  onFinish={handleSubmit}
                  style={{ width: '100%' }}
                  initialValues={{ message: '' }}
                >
                  <Form.Item name="message" className="no-space-form-item" rules={[{ required: true }]}>
                    <TextArea
                      ref={messageInput}
                      className={styles.input}
                      size="large"
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      placeholder="Text your message"
                      disabled={!selectedChatId}
                      autoFocus
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                          event.preventDefault();
                          form.submit();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="messageId" className="no-space-form-item hidden-form-item">
                    <Input type="hidden" disabled />
                  </Form.Item>
                </Form>
                <button
                  type="button"
                  aria-label="send message"
                  className={clsx(styles.action, styles.send, { [styles.spin]: send })}
                  onClick={(e) => {
                    e.preventDefault();
                    setSend(true);
                    form.submit();
                  }}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    response ? (
                      <CheckOutlined />
                    ) : (
                      <SendOutlined />
                    )
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/** Right sider */}
        <SiderRightAdditions
          onEmojiClick={handleAddEmoji}
          containerClassName={clsx(styles.additions, { [styles.open]: openAdditions.open })}
          wrapperClassName={styles.wrapper}
        />
      </div>
    </div>
  );
}

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(Chat);
