import React, { useEffect, useState } from 'react';

import {
  DatePicker,
  Form, Input, InputNumber, message, Select,
  SelectProps,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import TextArea from 'antd/es/input/TextArea';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

import { FieldData } from 'rc-field-form/lib/interface';
import { useTransactionsContext } from './context';
import { textField } from '../../../../../utils/validations';
import {
  Transaction, TransactionTypesEnum, useCategoriesGetAll, useTagsGetAll, useTransactionGetById,
} from '../../../../../hooks/api/transactions';
import { useBankaccountGetAll } from '../../../../../hooks/api/bankAccount';
import { enumToOptionsArray } from '../../../../../utils';

dayjs.extend(weekday);
dayjs.extend(localeData);

export const validationTransactions = yup.object().shape({
  name: textField,
});

type TransactionsFormProps = AnyObject
const { Option, OptGroup } = Select;
const options: SelectProps['options'] = [];

export default function TransactionsForm({
  id,
  setCategories,
  setAccount,
  ...props
}: TransactionsFormProps): React.ReactNode | null {
  const [form] = Form.useForm<Transaction>();
  const {
    setForm, setValid, initialState, setInitialState,
  } = useTransactionsContext();
  const [viewMode, setViewMode] = useState(false);
  const categoriesGetAll = useCategoriesGetAll();
  const tagsGetAll = useTagsGetAll();
  const bankAccount = useBankaccountGetAll();
  const transactionGetById = useTransactionGetById(id);
  const [triggerChange, setTriggerChange] = useState(Date.now());
  const [bankOptions, setBankOptions] = useState<{ label: string; value: string,}[]>([]);
  const [startingDate, setStartingDate] = useState<Dayjs>();
  const { loading, data, error } = transactionGetById;
  const [groupedOptions, setGroupedOptions] = useState<{
    label: string;
    options: { label: string; value: string }[];
  }[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(groupedOptions);

  useEffect(() => {
    if (!error && !loading && data) {
      let newData = {
        ...data,
      };

      if (data.transactionType) {
        const type = data.transactionType as string;

        if (type === 'NeutralIncome' || type === 'NeutralOutcome') {
          newData = {
            ...newData,
            transactionType: TransactionTypesEnum.Other,
          };
        }
        if (type === 'Income') {
          const filtered = groupedOptions.filter(
            (group) => group.label === 'Neutral income',
          );
        }
      }
      setInitialState(newData);
      form?.setFieldsValue(newData);
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (data?.account?.id) {
      form.setFieldValue('account', { label: data.account.name, value: data.account.id });
      const formattedGroups = bankAccount.data?.bankAccounts.filter((item) => item.id === data.account.id);

      if (formattedGroups && formattedGroups.length > 0) {
        const object = formattedGroups[0];

        setAccount(object);
      }
    }
    if (data?.category.id) {
      // console.log(data);
      form.setFieldValue('category', {
        label: data.category.name,
        options: { label: data.category.group.name, value: data.category.group.id },
      });
      const category = categoriesGetAll.data?.groups.map((group) => group.categories);

      if (category && category.length > 0) {
        const filteredData: any = category?.flatMap((group: any[]) => group
          .filter((cat) => cat.id === data.category.id));
        const object = filteredData[0];

        setCategories(object);
      }
    }
  }, [data]);
  useEffect(() => {
    bankAccount.fetch()
      .then((bankData) => {
        if (bankData?.bankAccounts) {
          const formattedGroups = bankData.bankAccounts.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          setBankOptions(formattedGroups);
        }
      })
      .catch(() => {
        setBankOptions([]);
      });
  }, []);

  useEffect(() => {
    categoriesGetAll.fetch()
      .then((categoriesData) => {
        if (categoriesData?.groups) {
          const formattedGroups = categoriesData.groups.map((group) => ({
            label: group.name,
            options: group.categories.flat().map((category) => ({
              label: category.name,
              value: category.id,
            })),
          }));

          setGroupedOptions(formattedGroups);
          setFilteredOptions(formattedGroups);
        }
      })
      .catch(() => {
        setGroupedOptions([]);
        setFilteredOptions([]);
      });
  }, []);

  useEffect(() => {
    setForm(form);
  }, [form]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      requiredMark="optional"
      validateTrigger="onSubmit"
      initialValues={{
        ...initialState,
        account: data?.account?.id,
        category: data?.category?.id,
      }}
      onValuesChange={(changedValues) => {
        const fieldName = Object.keys(changedValues)[0];

        form.setFields([
          {
            name: fieldName as FieldData['name'],
            errors: [],
          },
        ]);
      }}
      onFinish={
        async () => {
          setTriggerChange(Date.now());
          const formValues = form.getFieldsValue();

          try {
            await validationTransactions.validate(formValues, { abortEarly: false });
            setValid(true);
          } catch {
            setValid(false);
          }
        }
      }
      // eslint-disable-next-line react/jsx-props-no-multi-spaces
      // initialValues={{
      //   ...initialState, note: '', bank: { address: '' },
      // }}
      {...props}
    >
      <Form.Item<Transaction>
        label="Date"
        name="date"
        rules={[{ required: true }]}
        getValueProps={(value) => ({ value: value ? dayjs(value) : '' })}
      >
        <DatePicker
          style={{ width: '100%' }}
          format="DD.MM.YYYY"
          onChange={(date, dateString) => {
            const formattedDate = date ? date.format('YYYY-MM-DD') : null;

            form.setFieldValue('date', formattedDate);
          }}
          disabledDate={(current) => {
            const cutoffDate = data?.account?.accountingStartDate
              ? dayjs(data.account.accountingStartDate)
              : null;

            // if (!cutoffDate) {
            //   return false;
            // }
            if (startingDate) {
              return current && current.isBefore(startingDate, 'day');
            }

            return current && current.isBefore(cutoffDate, 'day');
          }}
        />
      </Form.Item>
      <Form.Item<Transaction>
        label="Account"
        name="account"
        rules={[{ required: true }]}
      >
        <Select
          options={bankOptions}
          onChange={(value) => {
            const accountType: any = bankAccount.data?.bankAccounts.filter((item) => item.id === value);

            const object = accountType[0];
            const { bank, accountingStartDate, ...updatedObject } = object;

            // eslint-disable-next-line unicorn/consistent-destructuring
            const selectedAccount = bankAccount.data?.bankAccounts.find(
              (item) => item.id === value,
            );

            if (selectedAccount) {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const { accountingStartDate } = selectedAccount;

              const selectedDate = form.getFieldValue('date');

              setStartingDate(dayjs(accountingStartDate));
              if (
                selectedDate
                && accountingStartDate
                && dayjs(selectedDate).isBefore(dayjs(accountingStartDate), 'day')
              ) {
                form.setFieldValue('date', null);
                message.warning('The selected date is earlier than the account creation date!');
              }
            }

            setAccount(updatedObject);
          }}
        />
      </Form.Item>
      <Form.Item<Transaction>
        label="Transaction"
        name="description"
        rules={[
          { required: true },
          { min: 3 },
          { max: 100 },
        ]}
      >
        <Input disabled={viewMode} />
      </Form.Item>
      <div style={{ display: 'flex', gap: '16px' }}>
        <Form.Item<Transaction>
          label="Category"
          name="category"
          style={{ width: '50%' }}
          rules={[{ required: true }]}
        >
          <Select
            options={filteredOptions}
            // showSearch
            filterOption={(inputValue, option) => {
              if (!option || !option.options) return false;

              return option.options.some((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
            }}
            onChange={(value) => {
              const category = categoriesGetAll.data?.groups.map((group) => group.categories);
              const filteredData: any = category?.flatMap((group: any[]) => group
                .filter((cat) => cat.id === value));
              const object = filteredData[0];

              if (object) {
                const currentTransactionType = form.getFieldValue('transactionType');

                if (!currentTransactionType) {
                  let autoTransactionType: TransactionTypesEnum | null = null;

                  if (['Fixed income', 'Flexible income'].includes(object.group.name)) {
                    autoTransactionType = TransactionTypesEnum.Income;
                  } else if (['Fixed expenses', 'Flexible expenses'].includes(object.group.name)) {
                    autoTransactionType = TransactionTypesEnum.Expenses;
                  } else {
                    autoTransactionType = TransactionTypesEnum.Other;
                  }
                  form.setFieldValue('transactionType', autoTransactionType);
                }
              }

              setCategories(object);
            }}
          />
        </Form.Item>
        <Form.Item<Transaction>
          label="Transaction type"
          name="transactionType"
          rules={[{ required: true }]}
          style={{ width: '50%' }}
        >
          <Select
            // options={typeOptions}
            options={enumToOptionsArray(TransactionTypesEnum)}
            defaultValue={() => (form ? form.getFieldValue('transactionType') : null)}
            onChange={(value, option) => {
              const selectedLabel = Array.isArray(option) ? null : option?.label;

              form.setFieldValue('category', '');
              setCategories('');

              if (selectedLabel === 'Income') {
                const filtered = groupedOptions.filter(
                  (group) => ['Fixed income', 'Flexible income'].includes(group.label),
                );

                setFilteredOptions(filtered);
              }
              if (selectedLabel === 'Expenses') {
                const filtered = groupedOptions.filter(
                  (group) => ['Fixed expenses', 'Flexible expenses'].includes(group.label),
                );

                setFilteredOptions(filtered);
              }
              if (selectedLabel === 'Other') {
                const filtered = groupedOptions.filter(
                  (group) => !['Fixed expenses', 'Flexible expenses', 'Fixed income', 'Flexible income']
                    .includes(group.label),
                );

                setFilteredOptions(filtered);
              }
            }}
          />
        </Form.Item>
      </div>
      <div style={{ display: 'flex', gap: '16px' }}>
        <Form.Item<Transaction>
          label="Amount"
          name="amount"
          style={{ width: '50%' }}
          rules={[{ required: true }]}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
        >
          <InputNumber
            disabled={viewMode}
            precision={2}
            controls={false}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item<Transaction>
          label="Tag"
          name="tags"
          style={{ width: '50%' }}
        >
          <Select
            mode="tags"
            tokenSeparators={[',']}
            options={tagsGetAll.data}
          />
        </Form.Item>
      </div>
      <Form.Item<Transaction>
        label="Note"
        name="note"
        normalize={(value) => value || ''}
      >
        <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
      </Form.Item>
      {/* { data ? (
        <div className="spin">
          <Spin />
        </div>
      ) : null} */}
    </Form>
  );
}
