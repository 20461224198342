import { ParamsType, ProTable } from '@ant-design/pro-components';
import { ActionType, ProColumns, ProTableProps } from '@ant-design/pro-table/es/typing';
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  FormOutlined,
  HistoryOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import React, { useMemo, useRef } from 'react';
import { Button, FormInstance } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { AnyObject } from '@triare/auth-redux';

import styles from './index.module.scss';

export interface TableOnRowSelect {
  params?: Record<string, string>;
  selectedRows?: string[];
  onRowSelection?: ((selectedRows: string[]) => void) | undefined;
}

export interface TableListItem {
  id: string | number;
  key: string | number;
}

export interface TableProps<T, U, V> extends ProTableProps<T, U, V> {
  actions?: ([
    (props: { text: React.ReactNode, record: T, id: number, className: string }) => React.ReactNode
  ] | [
    (props: { text: React.ReactNode, record: T, id: number, className: string } & AnyObject) => React.ReactNode,
    AnyObject
  ] | [
    string,
    (props: { text: React.ReactNode, record: T, id: number }) => ProColumns<T, V>
  ] | [
    string,
    (props: { text: React.ReactNode, record: T, id: number }) => ProColumns<T, V>,
    (props: { text: React.ReactNode, record: T, id: number }) => boolean
  ])[]
}

export const listAction = {
  form: (props: ButtonProps) => <Button key="form" {...props} type="text" icon={<FormOutlined />} />,
  edit: (props: ButtonProps) => <Button key="edit" {...props} type="text" icon={<EditOutlined />} />,
  delete: (props: ButtonProps) => <Button key="delete" {...props} type="text" icon={<DeleteOutlined />} />,
  reload: (props: ButtonProps) => <Button key="reload" {...props} type="text" icon={<ReloadOutlined />} />,
  copy: (props: ButtonProps) => <Button key="copy" {...props} type="text" icon={<CopyOutlined />} />,
  download: (props: ButtonProps) => <Button key="download" {...props} type="text" icon={<DownloadOutlined />} />,
  dropdown: (props: ButtonProps) => <Button key="download" {...props} type="text" icon={<EllipsisOutlined />} />,
};

function Table<T extends TableListItem, Params extends ParamsType = ParamsType, ValueType = 'text'>({
  options,
  pagination,
  columns,
  actions,
  form,
  search,
  ...props
}: TableProps<T, Params, ValueType>) {
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const newColumns = useMemo(() => {
    const list = (columns || []).map((column) => {
      if (column.hideInSearch === true) {
        return column;
      }

      return column;
      // column.
    });

    if (actions) {
      list.push({
        key: 'id',
        title: 'Actions',
        dataIndex: 'id',
        hideInSearch: true,
        width: actions?.length ? actions.length * 32 + 8 : 104,
        render: (text, record, id) => (
          <div className={styles.actions}>
            {actions.map(
              (action) => {
                if (typeof action[0] === 'string') {
                  const [key, createData, check] = action;

                  if ((!check || check({ text, record, id })) && typeof createData === 'function') {
                    const data = createData({ text, record, id });

                    // @ts-ignore - use the action icon in the list or use a custom element
                    return listAction[key] ? listAction[key](data) : data;
                  }
                } else {
                  const [Component, additionalProps] = action;

                  return (
                    <Component
                      key={Component.name}
                      id={id}
                      text={text}
                      record={record}
                      className={styles.actionBtn}
                      {...additionalProps}
                    />
                  );
                }

                return null;
              },
            ).filter((value) => value !== null)}
          </div>
        ),
      });
    }

    return list;
  }, []);

  return (
    <ProTable<T, Params, ValueType>
      formRef={formRef}
      actionRef={actionRef}
      className={styles.table}
      rowKey="key"
      dateFormatter="string"
      pagination={{
        defaultPageSize: 10,
        size: 'default',
        showQuickJumper: true,
        showTotal: undefined,
        ...pagination,
      }}
      onReset={() => {
        if (actionRef.current && formRef.current) {
          actionRef.current?.reset?.();
          // const newValues = Object.keys(formRef.current?.getFieldsValue())
          //   .map((name) => ({
          //     name,
          //     value: undefined,
          //   }));
          //
          // formRef.current?.setFieldsValue(newValues);
        }
      }}
      columns={newColumns}
      options={{
        density: false,
        search: false,
        reloadIcon: <HistoryOutlined />,
        ...(options || {}),
      }}
      search={search ? {
        labelWidth: 'auto',
        filterType: 'query',
        ...search,
      } : false}
      form={{
        syncToUrl: true,
        ...form,
      }}
      {...props}
    />
  );
}

export default Table;
