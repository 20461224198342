import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button, Form, Form as AntdForm,
} from 'antd';
import {
  OTPGenerateAction,
} from '@triare/auth-redux/dist/saga/auth/OTP';
import OTPVerification from '../../../../Common/Auth/OTPVerification';
import AlertSuccess from '../Form/Alert/Success';
import { config, useAuth } from '../../../../../store/auth';
import { createRulesForAntd, validationSignUp } from '../../../../../utils/validations';
import { PhoneNumberInput } from '../../../../Common/PhoneNumberInput';
import Submit from '../../../../Common/Form/Button/Submit';
import { useResendNewPhoneNumber } from '../../../../../hooks/api/auth';

import styles from './index.module.scss';

interface OTPCodeProps {
  email?: string;
  onBack: () => void;
  OTPGenerate?: (payload: OTPGenerateAction) => void;
}

const { displayName } = config;

const validationRules = createRulesForAntd(validationSignUp);

function OTPCode({ email, onBack, OTPGenerate }: OTPCodeProps): React.ReactNode {
  document.title = `${displayName}: OTPCode`;
  const {
    authorized, OTP,
    signUp: {
      data, loading, response, error, resendEmail,
    },
  } = useAuth();
  const [firstAuthorized, setFirstAuthorized] = useState<boolean>(!authorized);
  const resendNewPhoneNumber = useResendNewPhoneNumber();
  const [state, setState] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // if (authorized && firstAuthorized && !OTP.error && !OTP.response) {
    //   navigate('/welcome');
    // }
  }, [firstAuthorized, authorized, OTP.error, OTP.response]);

  useEffect(() => {
    if (!OTP.error && OTP.response) {
      setFirstAuthorized(true);
      navigate('/welcome');
    }
  }, [OTP.error, OTP.response]);
  useEffect(() => {
    if (!resendNewPhoneNumber.loading && resendNewPhoneNumber.response && !resendNewPhoneNumber.error) {
      setState(false);
    }
  }, [resendNewPhoneNumber.loading, resendNewPhoneNumber.response, resendNewPhoneNumber.error]);

  return (
    <div
      className={styles.wrapper}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <OTPVerification
        email={email}
        nameBtnFinish="Verify"
        alertSuccess={<AlertSuccess response={OTP.response} />}
      />
      <div>
        {state ? (
          <Form
            autoComplete="off"
            layout="vertical"
                // eslint-disable-next-line @typescript-eslint/no-shadow
            onFinish={(values) => {
              if (values) {
                resendNewPhoneNumber.fetch({ phoneNumber: values.phoneNumber });
                console.log(values.phoneNumber);
              }
              // if (data) {
              //   signUpTwoFA({
              //     ...data,
              //     phoneNumber,
              //   });
              // }
            }}
          >
            <AntdForm.Item
              className="custom-form-item"
              name="phoneNumber"
              label={`${t('contactPhone')}`}
              rules={[validationRules]}
            >
              <PhoneNumberInput
                placeholder="321567926"
                size="large"
              />
            </AntdForm.Item>
            <Submit
              loading={loading}
              size="large"
            >
              Update phone number
            </Submit>
          </Form>
        ) : (
          <Button
            size="large"
            type="link"
            style={{ width: '100%' }}
            loading={resendEmail.loading}
            onClick={() => setState(true)}
          >
            Change my phone number
          </Button>
        )}
      </div>
    </div>
  );
}

export default OTPCode;
