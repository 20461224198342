import { Empty } from 'antd';
import { useSearchParams } from 'react-router-dom';

import styles from './index.module.scss';

interface NoDataProps {
  title?: string;
  description?: string;
}

export default function NoData({ description, title }: NoDataProps) {
  const [searchParams] = useSearchParams();

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{ height: '117px' }}
      description={(
        <div className={styles.text}>
          <div style={{ width: 360 }}>
            <span style={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>{title || 'No data'}</span>
            <br />
            <span>{description}</span>
          </div>
        </div>
      )}
    />
  );
}
