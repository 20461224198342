import React, { useEffect } from 'react';
import {
  SignInAction,
  SignInByPhoneAction, SignInByServiceAction,
  SignInByUsernameAction,
} from '@triare/auth-redux/dist/saga/auth/signIn';
import { useNavigate } from 'react-router-dom';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { useTranslation } from 'react-i18next';
import OTPCode from './OTPCode';
import { useAuth } from '../../../../store/auth';
import Wrapper from '../Wrapper';
import store from '../../../../store';

type Values = SignInAction | SignInByUsernameAction | SignInByPhoneAction | SignInByServiceAction | null;

export interface ContentProps {
  onSubmit?: (values: Values) => void
}

export interface SignInProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any
}

export default function SignIn({ content: Content }: SignInProps) {
  const {
    user, access, authorized, signIn, OTP,
  } = useAuth();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (authorized && (signIn.response || OTP.response)) {
      navigate('/');
    }
  }, [authorized, signIn.response, OTP.response]);

  return (
    <Wrapper>
      {user && user.is2faActive && access && !authorized ? (
        <OTPCode
          onBack={() => store.dispatch(signOut())}
          // email={user?.email}
        />
      ) : (
        <Content />
      )}
    </Wrapper>
  );
}
