import {
  Button,
  Divider,
  List,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { BankAccountsButton } from './Form/ModalForm';
import { BankAccounts, useBankaccountGetAll } from '../../../../hooks/api/bankAccount';
import { IconImport } from '../../../Common/Icon';

import styles from '../index.module.scss';

export default function BankAccount({ setBanks }: { setBanks: (count: number) => void }): React.ReactNode | null {
  const { open, contextHolder } = useSimpleModal();
  const bankAccount = useBankaccountGetAll();
  const [bankAccountData, setBankAccountData] = useState<BankAccounts[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeModalId, setActiveModalId] = useState<string>('');
  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
    setActiveModalId('');
  };

  const handleClick = (id: string) => {
    setActiveModalId(id);
    setModalOpen(true);
  };

  const updateTab = () => {
    bankAccount.fetch().then(() => {
      setBankAccountData(bankAccount.data?.bankAccounts || []);
      setBanks(bankAccount.data?.bankAccounts.length || 0);
    });
  };

  useEffect(() => {
    if (bankAccount.data) {
      setBankAccountData(bankAccount.data?.bankAccounts);
      setBanks(bankAccount.data?.bankAccounts.length);
    }
  }, [bankAccount.data]);

  return (
    <div>
      <div className={styles.accountTitle}>
        <h2>Bank account</h2>
        <div>
          <Button icon={<IconImport />} type="primary">
            Conect account
          </Button>
          <BankAccountsButton
            id=""
            updateTab={updateTab}
            isModalOpen={isModalOpen}
            handleClick={() => handleClick('')}
            handleCloseModal={handleCloseModal}
          />
          {contextHolder}
        </div>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={bankAccountData}
        renderItem={(item) => (
          <List.Item>
            <div style={{ marginTop: 16 }} className={styles.card}>
              <div>
                <Typography.Title level={4} className={styles.title}>
                  <img
                    src={item.bank.logoUrl ? item.bank.logoUrl : '/media/Card_image.svg'}
                    alt=""
                    width={32}
                    style={{ marginRight: 8 }}
                  />
                  {item.name}
                </Typography.Title>
                <div className={styles.title}>
                  <strong>IBAN:</strong>
                  <strong>{item.iban.replace(/\s+/g, '').match(/.{1,4}/g)?.join(' ')}</strong>
                </div>
                <div style={{ color: '#000000A6' }}>
                  {`${item.bank.address || ''} ${item.bank.phoneNumber || ''}`}
                </div>
              </div>
              <div>
                <Divider style={{ margin: '10px 0' }} />
                <div className={styles.cardFooter}>
                  <div className={styles.title}>
                    <strong>{item.currency}</strong>
                    <strong>
                      {Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        .format(item.balance ?? 0)
                        .replace(/,/g, "'")}
                    </strong>
                  </div>
                  <BankAccountsButton
                    title="Details"
                    id={activeModalId}
                    text="Details"
                    updateTab={updateTab}
                    handleClick={() => handleClick(item.id)}
                    handleCloseModal={handleCloseModal}
                    isModalOpen={activeModalId === item.id}
                  />
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />
      {bankAccount.loading ? (
        <div className="spin">
          <Spin />
        </div>
      ) : null}
    </div>
  );
}
