import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import styles from './index.module.scss';

export interface SendSuccessProps {
  email: string | undefined;
  onResend: () => void
}

export default function SendSuccess({ email, onResend }: SendSuccessProps) {
  const navigate = useNavigate();

  return (
    <div className={styles.sendSuccess}>
      <h1 style={{ marginBottom: '10px' }}>Reset link sent!</h1>
      <span>
        To continue, please click the reset link in the email we sent to
        {' '}
        {email}
        .
      </span>
      <span style={{ margin: '8px 0' }}>
        If you don&apos;t see this email in your inbox, you may need to check your spam folder.
      </span>
      <Button size="large" type="link" block onClick={onResend}>
        Resend verification email
      </Button>
      {/* <Button size="large" type="link" block onClick={() => navigate('/sign-in')}>
        Back to log in
      </Button> */}
    </div>
  );
}
