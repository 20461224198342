import React, { useEffect, useState } from 'react';
import {
  Alert, Form, FormInstance,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '@triare/auth-redux';
import { connect } from 'react-redux';
import {
  OTP as otpAction,
  OTPAction,
  OTPClear as otpClearAction,
  OTPGenerate as otpGenerateAction,
  OTPGenerateAction,
  OTPGenerateClear as otpGenerateClearAction,
  OTPGenerateResponse,
} from '@triare/auth-redux/dist/saga/auth/OTP';
import Countdown from 'antd/es/statistic/Countdown';
import InputCodeWrapper from '../../Form/Input/Code';
import { moduleName, useAuth } from '../../../../store/auth';
import { RootState } from '../../../../store';
import { timeResendOTPCode } from '../../../../contstant';
import { getMessageInError } from '../../../../hooks/fetch';
import Submit from '../../Form/Button/Submit';

import styles from './index.module.scss';

interface FormValues {
  [key: string]: number;
}

interface OTPVerificationProps {
  email?: string;
  nameBtnFinish: string;

  beforeBtnFinish?: React.ReactNode;
  afterBtnFinish?: React.ReactNode;
  className?: string;
  onInit?: (form: FormInstance) => void;

  OTP: (payload: OTPAction) => void;
  OTPClear: () => void;
  loading: boolean;
  error: null | AnyObject;

  OTPGenerate: (payload?: OTPGenerateAction) => void;
  OTPGenerateClear: () => void;
  otpGenerateLoading: boolean;
  otpGenerateResponse: OTPGenerateResponse | null;
  otpGenerateError: null | AnyObject;

  alertSuccess: React.ReactNode;
}
const formatPhoneNumber = (phone: string): string => {
  const cleanedPhone = phone.replace(/\D/g, '');

  if (!/^\d+$/.test(cleanedPhone)) {
    throw new Error('Invalid phone number format');
  }

  const countryCode = phone.slice(0, 2);
  const part1 = phone.slice(2, 5);
  const part2 = phone.slice(5, 8);
  const part3 = phone.slice(8, 10);
  const part4 = phone.slice(10, 12);
  const formattedNumber = `${countryCode} ${part1} ${part2} ${part3} ${part4}`;

  return formattedNumber;
};

function OTPVerification({
  email,
  nameBtnFinish,
  alertSuccess,
  OTP,
  OTPClear,
  loading,
  error,
  OTPGenerate,
  OTPGenerateClear,
  otpGenerateLoading,
  otpGenerateResponse,
  otpGenerateError,
  className,
  onInit,
  beforeBtnFinish,
  afterBtnFinish,
}: OTPVerificationProps): React.ReactNode | null {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [resendClick, setResentClick] = useState(false);
  const [startTime, setStartTime] = useState(Date.now() - timeResendOTPCode);
  const [time, setTime] = useState(0);
  const calcTime = () => Math.round((startTime + timeResendOTPCode + 1 - Date.now()) / 1000);
  const [deadline, setDeadline] = useState<number>(Date.now() + timeResendOTPCode);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (otpGenerateResponse && !otpGenerateError) {
      setStartTime(Date.now());
      setDeadline(Date.now() + timeResendOTPCode);
      setIsTimerActive(true);
    }
  }, [otpGenerateResponse, otpGenerateError]);

  useEffect(() => {
    if (resendClick) {
      const id = setInterval(() => {
        const seconds = calcTime();

        if (seconds > 0) {
          setTime(seconds);
        } else {
          setTime(0);
          clearInterval(id);
          setResentClick(false);
        }
      }, 1000);

      return () => clearInterval(id);
    }

    return () => {};
  }, [resendClick]);

  useEffect(() => {
    if (onInit) {
      onInit(form);
    }
  }, [form]);

  const onFinishHandler = (values: FormValues) => {
    OTP({ secretCode: Object.values(values).join('') });
  };

  const handleResend = () => {
    OTPGenerate();
    setIsTimerActive(true);
  };

  const onTimerFinish = () => {
    setIsTimerActive(false);
  };

  return (
    <Form<FormValues> form={form} className={className} autoComplete="off" layout="vertical" onFinish={onFinishHandler}>
      <h1>Verify your phone number</h1>
      {error ? (
        <Alert
          type="error"
          message={getMessageInError(error)}
          closable
          onClose={OTPClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}

      {otpGenerateError ? (
        <Alert
          type="error"
          message={getMessageInError(otpGenerateError)}
          closable
          onClose={OTPGenerateClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}
      <span style={{ color: '#000000E0' }}>
        To continue, please enter 4-digit verification code sent to
        {' '}
        <br />
        {user?.phoneNumber
          ? (
            <strong>{`+${formatPhoneNumber(user?.phoneNumber as string)}`}</strong>
          )
          : null}
      </span>
      <InputCodeWrapper form={form} style={{ marginTop: resendClick ? '26px' : '15px' }} />
      {isTimerActive && (
      <div className={styles.timer}>
        <span>Resend verification code in</span>
        <Countdown
          value={deadline}
          onFinish={onTimerFinish}
          format="mm:ss"
        />
      </div>
      )}
      {!isTimerActive
        ? (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <a
              href="/generate-code"
              onClick={(e) => {
                e.preventDefault();
                if (!isTimerActive) {
                  handleResend();
                }
              }}
            >
              Resend verification code
            </a>
          </div>
        )
        : null}

      {alertSuccess}
      <div>
        {beforeBtnFinish}

        <Submit
          loading={loading || otpGenerateLoading}
          size="large"
        >
          {nameBtnFinish}
        </Submit>

        {afterBtnFinish}
      </div>
    </Form>
  );
}

export default connect(
  (state: RootState) => ({
    error: state[moduleName].OTP.error,
    loading: state[moduleName].OTP.loading,
    otpGenerateResponse: state[moduleName].OTP.generate.response,
    otpGenerateError: state[moduleName].OTP.generate.error,
    otpGenerateLoading: state[moduleName].OTP.generate.loading,
  }),
  {
    OTP: otpAction,
    OTPClear: otpClearAction,
    OTPGenerate: otpGenerateAction,
    OTPGenerateClear: otpGenerateClearAction,
  },
)(OTPVerification);
