import React, { createContext, useState } from 'react';
import { FormInstance } from 'antd';

import { SimpleProviderProps } from '../../../../../types';
import { BankAccounts } from '../../../../../hooks/api/bankAccount';

export interface BankAccountsContexttProps {
  initialState: BankAccounts | null,
  setInitialState: (data: BankAccounts) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: BankAccounts) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const BankAccountsContext = createContext<BankAccountsContexttProps>(defaultValue);

function BankAccountsProvider({
  children,
}: SimpleProviderProps) {
  const [initialState, setInitialState] = useState<BankAccounts | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  // useEffect(() => {
  //   form?.resetFields();
  //   form?.setFieldsValue(initialState);
  // }, [initialState]);

  return (
    <BankAccountsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        initialState,
        setInitialState,
        form,
        setForm,
        isValid,
        setValid,
      }}
    >
      {children}
    </BankAccountsContext.Provider>
  );
}

export default BankAccountsProvider;

export const useBankAccountsContext = ():
BankAccountsContexttProps => React.useContext(BankAccountsContext);
