import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  confirmEmail as confirmEmailAction,
  confirmClear as confirmClearAction,
  confirmErrorClear as confirmErrorClearAction, ConfirmEmailAction,
} from '@triare/auth-redux/dist/saga/auth/confirm';
import { Action } from '@triare/auth-redux';
import Wrapper from '../Wrapper';
import { getMessageInError } from '../../../../hooks/fetch';
import { createRulesForAntd, validationNewPassword } from '../../../../utils/validations';
import { useAuth } from '../../../../store/auth';
import OTPCode from '../SignUp/OTPCode';

const validationRules = createRulesForAntd(validationNewPassword);

export interface ConfirmEmailProps {
  mode?: string;
  confirmEmail: (payload: ConfirmEmailAction) => Action<ConfirmEmailAction>;
  confirmClear: () => Action;
  confirmErrorClear: () => Action;
}

function ConfirmEmail({
  mode: modeParam, confirmEmail, confirmErrorClear, confirmClear,
}: ConfirmEmailProps): React.ReactNode {
  const { confirm: { response, loading, error }, user } = useAuth();
  const { t } = useTranslation();
  const { mode = modeParam, secretKey } = useParams();
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    // if (secretKey && mode === 'none') {
    //   confirmEmail({
    //     secretKey,
    //   });
    // }
    if (secretKey) {
      confirmEmail({
        secretKey,
      });
    }
  }, [secretKey]);

  useEffect(() => {
    if (!loading && !error) {
      if (response) {
        setRedirect(true);
      } else if (redirect) {
        if (user && user.settings && !user.settings.isPhoneVerified) {
          console.log('bla');
        } else {
          // navigate('/');
        }
      }
    }
  }, [response, error, loading, redirect, user]);

  if (user && user.email) {
    return (
      <Wrapper>
        <OTPCode email={user.phoneNumber} onBack={() => navigate('/')} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 style={{ textAlign: 'center' }}>
        Your email confirm
        {' '}
        {/* eslint-disable-next-line no-nested-ternary */}
        {error ? '' : (response || redirect ? 'successfully!' : <Spin />)}
      </h1>

      {error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(error)}
          closable
          onClose={confirmErrorClear}
        />
      ) : null}
    </Wrapper>
  );
}

export default connect(null, {
  confirmEmail: confirmEmailAction,
  confirmClear: confirmClearAction,
  confirmErrorClear: confirmErrorClearAction,
})(ConfirmEmail);
