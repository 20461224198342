import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import OTPVerification from '../../../../Common/Auth/OTPVerification';
import AlertSuccess from '../Form/Alert/Success';
import { useAuth } from '../../../../../store/auth';

import styles from './index.module.scss';

interface OTPCodeProps {
  email?: string
  onBack?: () => void
}

function OTPCode({ email, onBack }: OTPCodeProps): React.ReactNode {
  const { OTP } = useAuth();
  const { t } = useTranslation();

  return (
    <div
      className={styles.wrapper}
    >
      <OTPVerification
        email={email}
        nameBtnFinish="Verify"
        alertSuccess={<AlertSuccess response={OTP.response} />}
        afterBtnFinish={onBack ? (
          <Button
            type="link"
            onClick={onBack}
            size="large"
          >
            {t('Back to Log in')}
          </Button>
        ) : undefined}
      />
    </div>
  );
}

export default OTPCode;
