/* eslint-disable @typescript-eslint/no-unused-vars, no-param-reassign */

import {
  config,
  State as AuthState,
  setActions,
  saga as authSaga,
  updateConfig,
  getLocalState,
  createUrl,
  reducers,
} from '@triare/auth-redux';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  spawn, takeLatest, select, delay,
} from 'redux-saga/effects';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useSelector } from 'react-redux';
import { UrlObject } from '@triare/auth-redux/src/config';
import { AnyObject } from '@triare/auth-redux/src/saga/common';
import { User as AuthUser } from '@triare/auth-redux/src/saga/auth/useMe';
import { RootState } from '../index';

export * from '@triare/auth-redux';

export default updateConfig({
  name: 'Finatium',
  displayName: 'Finatium',
  fetchDelay: Number.parseInt(process.env.REACT_APP_FETCH_DELAY || '0', 10),
  api: {
    url: process.env.REACT_APP_API || '',
  },
  signUp: {
    ...config.signUp,
    url: 'auth/sign-up',
    resendEmail: {
      url: 'auth/sign-up',
    },

  },
  forgotPassword: {
    ...config.forgotPassword,
    url: 'auth/send-link-on-forgot',
  },
  confirm: {
    ...config.confirm,
    url: 'auth/confirm-email-on-registration',
    needToSendTheType: false,
  },

  OTP: {
    ...config.OTP,
    url: 'auth/verify-phone-number',
    generate: {
      url: 'auth/send-sms-with-otp',
      requestBody: ({ phoneNumber }: any) => JSON.stringify({ phoneNumber }),
    },
    isTwoFAActiveColumnName: 'is2faActive',
  },
  resetPassword: {
    ...config.resetPassword,
    url: 'auth/set-new-password-on-forgot',
  },

  // signIn: {
  //   ...config.signIn,
  //   refreshToken: {
  //     ...config.refreshToken,
  //     method: HttpMethod.POST,
  //   },
  //   requestBody: {
  //     byEmail: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
  //     byUsername: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
  //     byPhone: ({ remember, ...data }: SignInAction) => JSON.stringify(data),
  //     byService: ({ method, remember, ...data }: SignInAction) => JSON.stringify(data),
  //   },
  //   fetchMeAfterSuccess: true,
  // },
  createUrl: (
    url: string | UrlObject,
    addToUrl?: string,
    payload?: PayloadAction,
    data?: AnyObject,
    _query?: AnyObject,
  ) => createUrl(url, addToUrl, payload, data, {
    ..._query,
    // lang: 'en',
  }),
});

export const moduleName = config.auth.name;

export interface User extends AuthUser {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isTwoFAActive: boolean;
  settings: {
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  };
}

export interface State extends AuthState {
  user: User | null;
  secretKey: string;
}

export function useAuth(): State {
  return useSelector((state: RootState) => state[moduleName]);
}

export const auth = createSlice({
  name: moduleName,
  initialState: getLocalState(),
  reducers: {
    ...reducers,

    // signInSuccess: (state, { payload }) => {
    //   Object.assign(state, payload);
    //
    //   state.signIn.loading = false;
    //   state.signIn.response = payload || null;
    //
    //   if (payload?.remember !== undefined) {
    //     state.remember = payload?.remember;
    //   }
    // },
  },
});

export const { actions } = auth;

setActions(actions);

function* selectAuthState() {
  return (yield select((state: RootState) => state[moduleName])) as State;
}

export function* saga(store: EnhancedStore) {
  yield spawn(authSaga, store);

  /** Handle socket connection */
  const { access } = store.getState()[config.auth.name];

  // if (access && access.token) {
  //   socketProvider.init(`${access.token}`);
  // }

  yield takeLatest([actions.signInSuccess.toString(), actions.refreshTokenSuccess.toString()], function* trigger() {
    yield delay(100);

    const authStore = (yield selectAuthState()) as State;

    // if (authStore && authStore.access && authStore.access.token) {
    //   socketProvider.reconnect(authStore.access.token);
    // }
  });
  /* * * * * */

  yield takeLatest(actions.signOutSuccess.toString(), () => {
    window.localStorage.removeItem('chats_data');
  });

  // yield takeLatest(actions.confirmSuccess.toString(), (data) => {
  //   console.log(data);
  // });

  // yield takeLatest(actions.userMeSuccess.toString(), function* trigger() {
  //   const authData = (yield getState()) as State;
  //
  //   if (authData && authData.user && authData.user.role === UserRoles.GUEST) {
  //     yield put(signOut());
  //
  //     window.location.href = '/sign-up';
  //   }
  // });
}
