import {
  DefaultFetchError, FetchGetId,
  useFetchGetId,
} from '../fetch';

export enum Months {
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
}

export enum DashboardGraphName {
  NET_ASSETS = 'net-assets',
  INCOME_AND_EXPENSES = 'income-and-expenses',
  SAVINGS = 'savings',
  ASSET_DEVELOPMENT = 'asset-development',
  WEALTH = 'wealth',
  INCOME_DEVELOPMENT = 'income-development',
  INCOME_DISTRIBUTION = 'income-distribution',
  EXPENSES_DEVELOPMENT = 'expenses-development',
  EXPENSES_DISTRIBUTION = 'expenses-distribution',
}

export interface DashboardResponse {
  data: useDashboardData[];
}

export interface useDashboardData{
  comment?: string,
  date: number | string,
  amount: number,
}

export const useDashboardData = (): FetchGetId<DashboardResponse, DefaultFetchError> => useFetchGetId(
  'dashboard',
  '',
  { autoStart: false, startStateLoading: false },
);

export interface IncomeExpensedResponse {
  data: IncomeExpensesData[];
}

export interface IncomeExpensesData{
  source: string,
  target: string,
  value: number
}

export const useIncomeExpensesData = (): FetchGetId<IncomeExpensedResponse, DefaultFetchError> => useFetchGetId(
  'dashboard/turnovers',
  '',
  { autoStart: false, startStateLoading: false },
);
