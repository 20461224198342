import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'antd';
import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';

import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import { useTransactionsContext } from '../context';
import TransactionsForm from '..';
import {
  useTransactioCreate,
  useTransactionDelete,
  useTransactionUpdate,

} from '../../../../../../hooks/api/transactions';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';

interface TransactionsProps{
  handleOpen?: (modalOpen: boolean) => void,
  title?: string,
  showFooter?: boolean,
  id?: string,
  updateTab?: () => void,
  isModalOpen: boolean;
}
// TODO update sections
/* eslint-disable max-len */

function TransactionsModal({
  id, title, handleOpen, updateTab, isModalOpen, showFooter = true,
}: TransactionsProps) {
  const [openModal, setModalOpen] = useState(true);
  const { open, contextHolder } = useSimpleModal();
  const {
    form, isValid, setInitialState,
  } = useTransactionsContext();
  const transactioCreate = useTransactioCreate();
  const transactionUpdate = useTransactionUpdate();
  const transactionDelete = useTransactionDelete();
  const [categories, setCategories] = useState({});
  const [account, setAccount] = useState();

  useMessageError([transactionUpdate]);
  useMessageSuccess([transactionUpdate], 'Transaction updated successfully');
  useMessageSuccess([transactionDelete], 'Transaction deleted successfully');
  useMessageError([transactioCreate]);
  useMessageSuccess([transactioCreate], 'Transaction created successfully');

  useEffect(() => {
    if (!transactionUpdate.error && !transactionUpdate.loading && transactionUpdate.data) {
      setModalOpen(false);
      handleOpen?.(false);
    }
  }, [transactionUpdate.error, transactionUpdate.loading, transactionUpdate.data]);

  useEffect(() => {
    if (!transactioCreate.error && !transactioCreate.loading && transactioCreate.data) {
      setModalOpen(false);
      handleOpen?.(false);
    }
  }, [transactioCreate.error, transactioCreate.loading, transactioCreate.data]);

  return (
    <Modal
      title={title || 'Adding transaction'}
      open={isModalOpen}
      centered
      closable
      destroyOnClose
      className="custom-modal"
      maskClosable={false}
      width={520}
      onClose={() => form?.resetFields()}
      footer={
        showFooter && (
          <div key="buttons" style={{ padding: '0 24px 24px' }}>
            {id ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  key="deleteTransaction"
                  danger
                  style={{ marginRight: '5px' }}
                  onClick={(e) => {
                    // e.preventDefault();
                    open({
                      icon: <ExclamationCircleFilled />,
                      title: 'Delete transaction?',
                      content: (
                        <span>
                          Are you sure you want to delete transaction
                          {' '}
                          <b>{form?.getFieldValue('name')}</b>
                          ?
                        </span>
                      ),
                      cancelText: 'Cancel',
                      okText: 'Delete',
                      okButtonProps: {
                        danger: true,
                      },
                      onOk: () => {
                        transactionDelete.fetch(id);
                        handleOpen?.(false);
                        updateTab?.();
                      },
                    });
                  }}
                >
                  Delete transaction
                </Button>
                {contextHolder}
                <Button
                  key="update"
                  type="primary"
                  // eslint-disable-next-line react/jsx-props-no-multi-spaces
                  onClick={() => {
                    form?.setFieldValue('descriptionIconUrl', '');

                    if (form) {
                      if (categories) {
                        const { label, options, ...output } = categories as {
                          label: string;
                          options: any;
                          [key: string]: any;
                        };
                        const { group } = categories as {
                          group: {groupType: string};
                          [key: string]: any;
                        };

                        form.setFieldValue('transactionType', group.groupType);
                        form.setFieldValue('category', output);
                      }
                      const newData = form.getFieldsValue();

                      if (account) {
                        const { label, bank, ...output } = account as {
                          label: string;
                          bank: any;
                        };

                        newData.account = output;
                        // form.setFieldValue('account', output);
                      }

                      if (form.getFieldValue('note') === null) {
                        newData.note = '';
                        form.setFieldValue('note', '');
                      }
                      form.validateFields().then((values) => {
                        console.log(newData);
                        setInitialState(newData);
                        transactionUpdate.fetch(newData, id);
                        updateTab?.();
                      }).catch((error_) => {
                        // console.log('Validation failed:', error_);
                      });
                    }
                  }}
                >
                  Update
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  key="cancel"
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    handleOpen?.(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  key="add"
                  type="primary"
                  // eslint-disable-next-line react/jsx-props-no-multi-spaces
                  onClick={() => {
                    form?.setFieldValue('descriptionIconUrl', '');
                    if (form) {
                      form.validateFields().then((values) => {
                        if (categories) {
                          form.setFieldValue('category', categories);
                          const { group } = categories as {
                            group: {groupType: string};
                            [key: string]: any;
                          };

                          form.setFieldValue('transactionType', group.groupType);
                        }
                        if (account) {
                          form.setFieldValue('account', account);
                        }
                        const newData = form.getFieldsValue();

                        setInitialState(newData);
                        transactioCreate.fetch(newData);
                        updateTab?.();
                      }).catch((error_) => {
                        // console.log('Validation failed:', error_);
                      });
                    }
                  }}
                >
                  Add transaction
                </Button>
              </div>
            )}
          </div>
        )
      }
      onCancel={() => {
        handleOpen?.(false);
      }}
    >
      <Row gutter={32}>
        <Col span={24}>
          <TransactionsForm id={id} setCategories={setCategories} setAccount={setAccount} />
        </Col>
      </Row>
    </Modal>
  );
}

export default TransactionsModal;

export function TransactionButton({
  id,
  title,
  updateTab,
  variant = 'button',
  text = 'Add new',
  modalProps,
  handleClick,
  handleCloseModal,
  isModalOpen,
}: {
  id?: string,
  title?: string,
  updateTab?: () => void,
  text?: string,
  variant?: 'button' | 'link',
  modalProps?: TransactionsProps,
  isModalOpen: boolean,
  handleClick: () => void,
  handleCloseModal: (isOpen: boolean) => void,

}) {
  return (
    <>
      <Button icon={<PlusCircleOutlined />} onClick={handleClick} {...(variant === 'link' ? { type: 'link', size: 'small' } : { type: 'primary' })}>
        {text}
      </Button>
      <TransactionsModal id={id} title={title} updateTab={updateTab} isModalOpen={isModalOpen} handleOpen={handleCloseModal} {...modalProps} />
    </>
  );
}
