import React, { useEffect, useState } from 'react';

import {
  DatePicker,
  Form, Input, InputNumber, Select,
  Spin,
} from 'antd';
import * as yup from 'yup';
import { AnyObject } from '@triare/auth-redux';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { FieldData } from 'rc-field-form/lib/interface';
import {
  AccountTypeEnum, BankAccounts, CurrencyEnum,
  useBankaccountGetById,
} from '../../../../../hooks/api/bankAccount';
import { useBankAccountsContext } from './context';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import { enumToOptionsArray } from '../../../../../utils';
import {
  createRulesForAntd, ibanField, bankName,
} from '../../../../../utils/validations';

dayjs.extend(weekday);
dayjs.extend(localeData);

export const validationBankName = () => yup.object().shape({
  name: bankName,
});
export const validationBankAccount = yup.object().shape({
  iban: ibanField,
  name: bankName,
  balance: yup
    .number()
    .typeError('')
    .test(
      'is-decimal',
      'Balance must have at most 2 decimal places',
      (value) => {
        if (value == null) return true;

        return /^-?\d+(\.\d{1,2})?$/.test(value.toString());
      },
    ),

  bank: yup.object().shape({
    name: yup.string()
      .min(3, 'Must be at least 3 characters')
      .max(100, 'Cannot be more than 100 characters.'),
    // .required('Required'),
  }),
  accountType: yup
    .string(),
  currency: yup
    .string(),
  accountingStartDate: yup.date()
    .typeError('Invalid date format'),
});

type BankAccountFormProps = AnyObject
const { Option } = Select;

export default function BankAccountForm({
  id, setHasTransaction,
  ...props
}: BankAccountFormProps): React.ReactNode | null {
  const [form] = Form.useForm<BankAccounts>();
  const {
    setForm, setValid, initialState, setInitialState,
  } = useBankAccountsContext();
  const [viewMode, setViewMode] = useState(false);
  const bankaccountGetById = useBankaccountGetById(id);
  const [triggerChange, setTriggerChange] = useState(Date.now());
  const { loading, data, error } = bankaccountGetById;

  const validationRules = createRulesForAntd(validationBankAccount);

  useEffect(() => {
    setForm(form);
  }, [form]);

  useEffect(() => {
    if (!error && !loading && data) {
      const newData = {
        ...data,
        // bank: { ...data.bank, name: data.bank.name },
        accountingStartDate: dayjs(data?.accountingStartDate),
      };

      setInitialState(newData);
      form?.setFieldsValue(newData);
      if (data.hasTransactions) {
        setHasTransaction(data.hasTransactions);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (id) {
      setViewMode(true);
    }
  }, [id]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      requiredMark="optional"
      validateTrigger="onSubmit"
      onValuesChange={(changedValues) => {
        const fieldName = Object.keys(changedValues)[0];

        form.setFields([
          {
            name: fieldName as FieldData['name'],
            errors: [],
          },
        ]);
      }}
      onFinish={
        async () => {
          setTriggerChange(Date.now());
          const formValues = form.getFieldsValue();

          try {
            await validationBankAccount.validate(formValues, { abortEarly: false });
            setValid(true);
            if (!formValues.bank?.address) {
              formValues.bank.address = '';
            }
            if (!formValues.note) {
              formValues.note = '';
            }
          } catch {
            setValid(false);
          }
        }
      }
      // initialValues={{
      //   ...initialState, note: '', bank: { address: '', phoneNumber: '' },
      // }}
      {...props}
    >
      <Form.Item<BankAccounts>
        label="IBAN"
        name="iban"
        rules={[
          { required: true, message: 'IBAN is required' },
          { min: 11 },
          { max: 34 },
          {
            validator: (_, value) => {
              if (!value || /^[A-Z]{2}/.test(value)) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('IBAN must start with two letters'));
            },
          },
        ]}
      >
        <Input placeholder="Please enter" />
      </Form.Item>

      <Form.Item<BankAccounts>
        label="Bank"
        name={['bank', 'name']}
        rules={[
          { required: true, message: 'Bank is required' },
          { min: 3 },
          { max: 34 },
        ]}
        normalize={(value) => value || ''}
      >
        <Input placeholder="Please enter" />
      </Form.Item>
      <Form.Item<BankAccounts>
        label="Account name"
        name="name"
        rules={[
          { required: true, message: 'Account name is required' },
          { min: 3 },
          { max: 34 },
        ]}
      >
        <Input placeholder="Please enter" />
      </Form.Item>
      <Form.Item<BankAccounts>
        label="Account type"
        name="accountType"
        rules={[validationRules, { required: true }]}
      >
        <Select options={enumToOptionsArray(AccountTypeEnum)} placeholder="Select" />
      </Form.Item>
      <div style={{ display: 'flex', gap: '16px' }}>
        <Form.Item<BankAccounts>
          label="Balance"
          name="balance"
          style={{ width: '50%' }}
          rules={[validationRules, { required: true }]}
          normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
        >
          <InputNumber
            precision={2}
            controls={false}
            style={{ width: '100%' }}
            placeholder="0.00"
            disabled={data?.hasTransactions}
          />
        </Form.Item>
        <Form.Item<BankAccounts>
          label="Currency"
          name="currency"
          style={{ width: '50%' }}
          rules={[validationRules, { required: true }]}
        >
          <Select options={enumToOptionsArray(CurrencyEnum)} placeholder="Select" disabled={data?.hasTransactions} />
        </Form.Item>
      </div>
      <Form.Item<BankAccounts>
        label="Accounting start date"
        name="accountingStartDate"
        rules={[validationRules, { required: true }]}
        getValueProps={(value) => ({ value: value ? dayjs(value) : '' })}
      >
        <DatePicker
          style={{ width: '100%' }}
          format="DD.MM.YYYY"
          onChange={(date, dateString) => {
            const formattedDate = date ? date.format('YYYY-MM-DD') : null;

            form.setFieldValue('accountingStartDate', formattedDate);
          }}
          disabled={data?.hasTransactions}
        />
      </Form.Item>
      <Form.Item<BankAccounts>
        label="Bank address"
        name={['bank', 'address']}
      >
        <Input placeholder="Please enter" />
      </Form.Item>
      <Form.Item
        className="custom-form-item"
        name={['bank', 'phoneNumber']}
        label="Bank phone number"
      >
        <PhoneNumberInput
          placeholder="321567926"
        />
      </Form.Item>
      <Form.Item<BankAccounts>
        label="Note"
        name="note"
        normalize={(value) => value || ''}
      >
        <TextArea autoSize={{ minRows: 1, maxRows: 6 }} placeholder="Please enter" />
      </Form.Item>

      {loading ? (
        <div className="spin">
          <Spin />
        </div>
      ) : null}
    </Form>
  );
}
