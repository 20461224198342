import React, {
  Key, useCallback, useEffect, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { RequestData } from '@ant-design/pro-table';
import {
  Button, Dropdown, Empty,
  Tag,
} from 'antd';
import {
  CalculatorOutlined, DeleteOutlined, EllipsisOutlined, ExclamationCircleFilled,
} from '@ant-design/icons';
import { AnyObject } from '@triare/auth-redux';
import { ProColumns } from '@ant-design/pro-table/es/typing';
import dayjs from 'dayjs';
import { MenuInfo } from 'rc-menu/lib/interface';
import Table, { TableOnRowSelect } from '../../../Common/Table';

import { getRandomColor, getSorterParams, queryFilterParams } from '../../../../utils';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useMessageError, useMessageSuccess } from '../../../../hooks/common';
import { IconImport, IconSplit } from '../../../Common/Icon';
import {
  TransactionTable, useCategoryGetAll, useTagsGetAll, useTransactionDelete,
  useTransactionTableGet,
} from '../../../../hooks/api/transactions';
import TransactionsModal, { TransactionButton } from './Form/ModalForm';
import TransactionsProvider from './Form/context';
import { CurrencyEnum, useAccountstGetAll } from '../../../../hooks/api/bankAccount';

function DropdownComponent({ record, setTableKey }: { record: TransactionTable } & AnyObject) {
  const { open, contextHolder } = useSimpleModal();
  const transactionDelete = useTransactionDelete();

  useMessageError([transactionDelete]);
  useMessageSuccess([transactionDelete], 'Transaction deleted successfully');

  useEffect(() => {
    if (!transactionDelete.error
      && !transactionDelete.loading
      && transactionDelete.data
      && transactionDelete.data.success) {
      setTableKey(Date.now());
    }
  }, [transactionDelete.error, transactionDelete.loading, transactionDelete.data]);

  const commonItems = [
    {
      key: 'Split',
      label: 'Split',
      icon: <IconSplit />,
      onClick: ({ domEvent }: MenuInfo) => {
        domEvent.stopPropagation();
      },
    },
    {
      key: 'Offset',
      label: 'Offset',
      icon: <CalculatorOutlined />,
      onClick: ({ domEvent }: MenuInfo) => {
        domEvent.stopPropagation();
      },
    },
    {
      key: 'Delete',
      label: 'Delete',
      danger: true,
      icon: <DeleteOutlined />,
      onClick: ({ domEvent }: MenuInfo) => {
        domEvent.stopPropagation();
        open({
          icon: <ExclamationCircleFilled />,
          title: 'Delete transaction?',
          content: (
            <span>
              Are you sure you want to delete transaction
              {' '}
              {/* <b>{record.name}</b> */}
              ?
            </span>
          ),
          cancelText: 'Cancel',
          okText: 'Delete',
          okButtonProps: {
            danger: true,
          },
          onOk: () => {
            transactionDelete.fetch(record.id);
            setTableKey(Date.now());
          },
        });
      },
    },
  ];

  return (
    <div tabIndex={-1} role="button" onClick={(e) => e.stopPropagation()}>
      <Dropdown
        menu={{ items: commonItems }}
        placement="bottomRight"
        arrow
      >
        <EllipsisOutlined />
      </Dropdown>
      {contextHolder}
    </div>

  );
}

function ToolBar({ setTableKey }: { setTableKey: React.Dispatch<React.SetStateAction<number>> }) {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeModalId, setActiveModalId] = useState<string>('');
  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
    setActiveModalId('');
  };

  const handleClick = (id: string) => {
    setActiveModalId(id);
    setModalOpen(true);
  };

  const updateTab = () => {
    setTableKey(Date.now());
  };

  return [
    <Button
      key="add"
      icon={<IconImport style={{ color: 'black' }} />}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      Import .csv
    </Button>,

    // eslint-disable-next-line react/jsx-key
    <TransactionsProvider>
      <TransactionButton
        key="addNewTransaction"
        id=""
        updateTab={updateTab}
        isModalOpen={isModalOpen}
        handleClick={() => handleClick('')}
        handleCloseModal={handleCloseModal}
      />
    </TransactionsProvider>,
  ];
}

export default function TransactionListContent({
  params, selectedRows, onRowSelection,
}: TableOnRowSelect): React.ReactNode | null {
  const transactionGet = useTransactionTableGet();
  const bankAccount = useAccountstGetAll();
  const categoryGetAll = useCategoryGetAll();
  const tagsGetAll = useTagsGetAll();
  const [, setSearchParams] = useSearchParams();
  const [tableKey, setTableKey] = useState<number>(Date.now());
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeModalId, setActiveModalId] = useState<string>('');

  const handleCloseModal = (isOpen: boolean) => {
    setModalOpen(isOpen);
    setActiveModalId('');
  };

  const handleClick = (id: string) => {
    setActiveModalId(id);
    setModalOpen(true);
  };

  const updateTab = () => {
    setTableKey(Date.now());
  };

  const columns: ProColumns<TransactionTable>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      valueType: 'dateRange',
      search: {
        transform: (value) => (value ? ({
          dateFrom: value[0],
          dateTo: value[1],
        }) : undefined),
      },
      render: (text, record) => (
        dayjs(record.date).format('DD.MM.YYYY')
      ),
    },
    {
      title: 'Account',
      dataIndex: ['account', 'name'],
      valueType: 'select',
      key: 'accountName',
      fieldProps: {
        showSearch: true,
      },
      search: {
        transform: (value) => (value ? ({
          accountId: value,
        }) : undefined),
      },
      request: () => new Promise((resolve) => {
        bankAccount.fetch()
          .then((data) => resolve(Array.isArray(data) ? data : []))
          .catch(() => resolve([]));
      }),
    },
    {
      title: 'Transaction',
      dataIndex: ['description'],
      key: 'transaction',
      hideInSearch: true,
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {/* <img
            src={record.descriptionIconUrl}
            alt={record.description}
          /> */}
          <span>{record.description}</span>
        </div>
      ),
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      hideInSearch: true,
      fieldProps: {
        showSearch: true,
      },
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            color: record.transactionType === 'Income' ? '#389E0D' : '',

          }}
        >
          {`${record.transactionType === 'Income' ? '+' : '-'}${Intl.NumberFormat(
            'en-US',
            { minimumFractionDigits: 2, maximumFractionDigits: 2 },
          )
            .format(record.amount)
            .replace(/,/g, "'")}
          `}

        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      // valueType: 'select',
      valueType: 'digitRange',
      key: 'amount',
      sorter: true,
      hideInTable: true,
      search: {
        transform: (value) => (value ? ({
          amountFrom: value[0],
          amountTo: value[1],
        }) : undefined),
      },
      fieldProps: {
        showSearch: true,
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      hideInSearch: true,
      render: (value) => (
        `${value}`
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      valueType: 'select',
      valueEnum: CurrencyEnum,
      hideInTable: true,
      render: (value) => (
        `${value}`
      ),
    },
    {
      // categoryGetAll
      title: 'Category',
      dataIndex: ['category'],
      key: 'category',
      sorter: true,
      valueType: 'select',
      fieldProps: {
        showSearch: true,
      },
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <img
            src={record.category.iconUrl}
            alt={record.category.iconUrl}
          />
          <span>{record.category.name}</span>
        </div>
      ),
      search: {
        transform: (value) => (value ? ({
          categoryId: value,
        }) : undefined),
      },
      request: () => new Promise((resolve) => {
        categoryGetAll.fetch()
          .then((data) => resolve(Array.isArray(data) ? data : []))
          .catch(() => resolve([]));
      }),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      hideInSearch: true,
    },
    {
      title: 'Tag',
      dataIndex: 'tags',
      key: 'tags',
      valueType: 'select',
      fieldProps: {
        mode: 'multiple',
        placeholder: 'Select tags',
      },
      search: {
        transform: (value) => (value && value.length > 0 ? ({
          tags: value,
        }) : undefined),
      },
      render: (_, record) => {
        if (record.tags && record.tags.length > 0) {
          return (
            <>
              {record.tags.map((tag, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tag key={index} color={getRandomColor()}>{tag}</Tag>
              ))}
            </>
          );
        }

        return null;
      },
      request: () => new Promise((resolve) => {
        tagsGetAll
          .fetch()
          // eslint-disable-next-line consistent-return
          .then((data) => {
            if (Array.isArray(data)) {
              return resolve(data);
            }
            resolve([]);
          })
          .catch(() => resolve([]));
      }),
    },
  ];

  const tableRequest = useCallback(async (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TransactionTable>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '20',
      orderByColumn: 'createdAt',
      orderBy: 'DESC',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...params, ...getSorterParams(sorter) }), { replace: true });

    const response = await transactionGet.fetch({
      ...newParams,
      ...params,
      tab: undefined,
    });

    if (response) {
      const { data, total } = response;

      return ({
        data: data.map((item) => ({
          ...item,
          key: item.id,
        })),
        success: true,
        total,
      });
    }

    return ({ data: [], success: false, total: 0 });
  }, []);

  useMessageError([transactionGet]);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as string[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      <Table<TransactionTable>
        locale={{
          emptyText: <Empty
            description="No added transactions"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />,
        }}
        key={tableKey}
        className="transparent"
        rowClassName={onRowSelection ? undefined : 'cursor-pointer'}
        headerTitle="Transaction list"
        toolBarRender={onRowSelection ? undefined : () => [<ToolBar key="toolbar" setTableKey={setTableKey} />]}
        request={tableRequest}
        columns={columns}
        onRow={onRowSelection ? undefined : (record) => ({
          onClick: (event) => {
            event.stopPropagation();
            handleClick(record.id);
          },
        })}
        actions={[
          [DropdownComponent, { setTableKey, setModalOpen }],
        ]}
        search={{
          searchText: 'Filter',
          resetText: 'Clear',
        }}
        options={{
          search: {
            name: 'search',
          },
        }}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['20', '40', '80'],
          defaultPageSize: 20,
          showQuickJumper: false,
          position: ['bottomRight'],
        }}
        columnsState={{ persistenceKey: 'pro-table-transactions', persistenceType: 'localStorage' }}
        // rowSelection={!!onRowSelection && rowSelection}
      />
      <TransactionsProvider>
        <TransactionsModal
          title="Transaction details"
          id={activeModalId}
          isModalOpen={isModalOpen}
          updateTab={updateTab}
          handleOpen={handleCloseModal}
        />
      </TransactionsProvider>
    </>
  );
}
