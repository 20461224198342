import React from 'react';
import { config } from '@triare/auth-redux';
import { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import OverviewContent from '../../components/Pages/Overview';

const { displayName } = config;
const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Item 1',
  },
  {
    key: '2',
    label: 'Item 2',
  },
  {
    key: '3',
    label: 'Item 3',
  },
];

export default function Overview(): React.ReactNode | null {
  document.title = `${displayName}: Home page`;
  const { t } = useTranslation();

  return (
    // disableLeftMenu={user && user.role ? user?.role === UserRoles.APPLICANT : true}
    // leftMenu={<LeftMenu />}
    <Layout>
      {/* <HomeContent /> */}
      <div style={{ textAlign: 'center' }}>
        {/* {t('header.overview')} */}
        <OverviewContent />
      </div>
    </Layout>
  );
}
