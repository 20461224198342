import { Dayjs } from 'dayjs';
import {
  DefaultFetchError,
  FetchCreate,
  FetchDelete,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from '../fetch';
import { AccountTypeEnum, CurrencyEnum } from './bankAccount';

export enum TransactionTypeEnum {
  Income = 'Income',
  Expenses = 'Expenses',
  NeutralIncome = 'Neutral Income',
  NeutralOutcome = 'Neutral Outcome',
}
export enum TransactionTypesEnum {
  Income = 'Income',
  Expenses = 'Expenses',
  Other = 'Other',
}

export interface Transaction extends TransactionParam {
  formValues?: { label: string; value: string; };
  id: string;
  createdAt: string;
  updatedAt: string;
}

export interface Category {
  id: string;
  name: string;
  iconUrl: string;
  group: CategoryGroup;
  createdAt: string;
  updatedAt: string;
}

export interface CategoryGroup {
  id: string;
  name: string;
  iconUrl: string;
  groupType: string;
  createdAt: string;
  updatedAt: string;
}

export interface Account {
  label?: string;
  id: string;
  name: string;
  iban: string;
  balance: number;
  accountingStartDate?: string | Dayjs,
  accountType: AccountTypeEnum;
  currency: CurrencyEnum;
  note: string;
  createdAt: string;
  updatedAt: string;
}

export interface TransactionParam {
  date: string;
  description: string;
  descriptionIconUrl: string;
  amount: number;
  amountCHF: number;
  currency: CurrencyEnum;
  transactionType: TransactionTypeEnum | TransactionTypesEnum;
  note: string;
  kind: string;
  tags: string[];
  category: Category;
  account: Account;
  iconUrl: string;
}

interface TransactionGetParams extends PagingParams {
  orderByColumn?: 'createdAt';
}

export const useTransactionGet = <DD = TransactionTableData>(
  decorateData?: (data: PagingDataResponse<Transaction>) => DD,
): FetchGet<
    PagingDataResponse<Transaction>,
    TransactionGetParams,
    DefaultFetchError,
    DD
  > => useFetchGet(
    'transactions',
    { autoStart: false, startStateLoading: false, decorateData },
  );
export const useTagsGetAll = <DD = { value: string; label: string }[]>(
  decorateData: (data: string[]) => DD = (data) => {
    const uniqueTags = [...new Set(data)];

    return uniqueTags.map((tag) => ({ value: tag, label: tag })) as DD;
  },
  autoStart = true,
): FetchGet<
    { data: { tags: string[] | null }[]; meta: any },
    undefined,
    DefaultFetchError,
    DD
  > => useFetchGet('transactions', {
    autoStart,
    startStateLoading: autoStart,
    decorateData: (response) => {
      if (Array.isArray(response.data)) {
        const allTags = response.data
          .flatMap((item) => item.tags || [])
          .filter((tag) => typeof tag === 'string');

        return decorateData(allTags);
      }
      console.error('Invalid data format:', response);

      return [] as DD;
    },
  });

export const useCategoryGetAll = <DD = { value: string; label: string }[]>(
  decorateData: (categories: { name: string; id: string }[]) =>
      DD = (categories) => categories.map((item) => ({ value: item.id, label: item.name })) as DD,
  autoStart = true,
): FetchGet<
    { categories: { name: string; id: string }[]; meta: any },
    undefined,
    DefaultFetchError,
    DD
  > => useFetchGet(
    'categories/all',
    {
      autoStart,
      startStateLoading: autoStart,
      decorateData: (response) => {
        if (Array.isArray(response.categories)) {
          return decorateData(response.categories);
        }
        console.error('Invalid data format:', response);

        return [] as DD;
      },
    },
  );

export interface TransactionTable extends Transaction {
    key: string;
  }

export interface TransactionTableData {
    data: TransactionTable[];
    total: number;
  }

export const useTransactionTableGet = () => useTransactionGet<TransactionTableData>(
  ({ data, meta }: PagingDataResponse<Transaction>): TransactionTableData => ({
    data: data.map((item: Transaction): TransactionTable => ({
      key: item.id,
      ...item,
    })),
    total: meta.totalItems,
  }),
);

export const useTransactioCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, TransactionParam> => (
  useFetchCreate('transactions')
);

export const useTransactionGetById = <DD = Transaction>(
  id?: string,
  decorateData?: (data: Transaction) => DD,
): FetchGetId<
      Transaction,
      DefaultFetchError,
      unknown,
      DD
    > => useFetchGetId(
    'transactions',
    id,
    {
      autoStart: !!id,
      decorateData,
    },
  );

export interface Categories {
  id: string;
  name: string;
  iconUrl: string;
  groupId: string;
  createdAt: string;
  updatedAt: string;
}

export interface GroupCategories {
  id: string;
  categories: Categories[][];
  name: string;
  groupType: TransactionTypeEnum;
  createdAt: string;
  updatedAt: string;
}

export interface GroupsResponse {
  groups: GroupCategories[];
}
export const useCategoriesGetAll = (): FetchGet<GroupsResponse, DefaultFetchError> => (
  useFetchGet('group-of-categories/all-with-categories')
);

export const useTransactionDelete = (id?: string): FetchDelete<
    FetchSuccess, DefaultFetchError
  > => (
  useFetchDelete('transactions', id)
);

export const useTransactionUpdate = (id?: string): FetchUpdate<
  FetchSuccess, DefaultFetchError, TransactionParam> => (
  useFetchUpdate('transactions', id)
);
