import React from 'react';
import { config } from '@triare/auth-redux';
import { useLocation } from 'react-router-dom';

import { CheckOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface SimpleProps {
  children: React.ReactNode
}
const { displayName } = config;

export default function AuthSteps(): React.ReactNode {
  const location = useLocation();

  return (
    <div>
      {location.pathname === '/sign-up' || location.pathname === '/sign-up/information'
      || document.title === `${displayName}: OTPCode`
      || location.pathname === '/welcome'
        ? (
          <div className={styles.steps}>
            <div className={styles.step}>
              <div className={styles.stepsItem}>
                {
                  location.pathname === '/sign-up/information'
                  || location.pathname === '/welcome'
                    ? (
                      <div className={styles.stepIcon} style={{ backgroundColor: '#E6F4FF' }}>
                        <CheckOutlined style={{ color: '#1677FF', fontSize: '12px' }} />
                      </div>
                    )
                    : (
                      <div className={styles.stepIcon} style={{ backgroundColor: '#1677FF' }}>
                        1
                      </div>
                    )
                }

                <span>Personal info</span>
              </div>
              <div className={styles.descriptions}>
                Please enter your information and proceed to the next step so we can build your account.
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepsItem}>
                {
                  location.pathname === '/welcome'
                    ? (
                      <div className={styles.stepIcon} style={{ backgroundColor: '#E6F4FF' }}>
                        <CheckOutlined style={{ color: '#1677FF', fontSize: '12px' }} />
                      </div>
                    )
                    : (
                      <div
                        className={styles.stepIcon}
                        style={
                          location.pathname === '/sign-up/information'
                          || document.title === `${displayName}: OTPCode`
                            ? { backgroundColor: '#1677FF' } : {}
                          }
                      >
                        2
                      </div>
                    )
                  }

                <span>Account verification</span>
              </div>
              <div
                className={styles.descriptions}
                style={
                  location.pathname === '/sign-up/information'
                  || document.title === `${displayName}: OTPCode`
                  || location.pathname === '/welcome' ? {} : { color: '#00000000' }
              }
              >
                Please verify your email and phone number.
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepsItem}>
                <div
                  className={styles.stepIcon}
                  style={
                    location.pathname === '/welcome'
                      ? { backgroundColor: '#1677FF' } : {}
                    }
                >
                  3
                </div>
                <span>Account is created</span>
              </div>
              <div
                className={styles.descriptions}
                style={
                  location.pathname === '/welcome'
                    ? { border: 'none' }
                    : { border: 'none', color: '#00000000' }
              }
              >
                You’re all set to take control, manage your finances, and reach your goals with confidence!
              </div>
            </div>
          </div>
        )
        : (<h3>Spare Ziet und Gäld!</h3>)}
    </div>
  );
}
