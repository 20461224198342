import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'antd';
import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import BankAccountForm from '..';
import { useBankAccountsContext } from '../context';
import {
  useBankAccountDelete,
  useBankAccountsCreate,
  useBankAccountUpdate,
} from '../../../../../../hooks/api/bankAccount';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import { useMessageError, useMessageSuccess } from '../../../../../../hooks/common';

dayjs.extend(weekday);
dayjs.extend(localeData);

interface BankAccounProps{
  handleOpen?: (modalOpen: boolean) => void,
  title?: string,
  showFooter?: boolean,
  id?: string,
  updateTab?: () => void,
  isModalOpen: boolean;
  hasTransactions?: boolean;
}
// TODO update sections
/* eslint-disable max-len */

function BankAccountModal({
  id,
  title,
  handleOpen,
  updateTab,
  isModalOpen,
  showFooter = true,
}: BankAccounProps) {
  const [openModal, setModalOpen] = useState(true);
  const { open, contextHolder } = useSimpleModal();
  const {
    form, isValid, setInitialState,
  } = useBankAccountsContext();
  const [hasTransaction, setHasTransaction] = useState(false);
  const bankAccountsCreate = useBankAccountsCreate();
  const bankAccountUpdate = useBankAccountUpdate();
  const bankAccountDelete = useBankAccountDelete();

  useMessageError([bankAccountUpdate]);
  useMessageSuccess([bankAccountUpdate], 'Account updated successfully');
  useMessageError([bankAccountDelete]);
  useMessageSuccess([bankAccountDelete], 'Account deleted successfully');
  useMessageError([bankAccountsCreate]);
  useMessageSuccess([bankAccountsCreate], 'Account created successfully');

  useEffect(() => {
    if (!bankAccountUpdate.error && !bankAccountUpdate.loading && bankAccountUpdate.data) {
      // setModalOpen(false);
      handleOpen?.(false);
      updateTab?.();
    }
  }, [bankAccountUpdate.error, bankAccountUpdate.loading, bankAccountUpdate.data]);

  useEffect(() => {
    if (!bankAccountDelete.error && !bankAccountDelete.loading && bankAccountDelete.data) {
      handleOpen?.(false);
      updateTab?.();
    }
  }, [bankAccountDelete.error, bankAccountDelete.loading, bankAccountDelete.data]);

  useEffect(() => {
    if (!bankAccountsCreate.error && !bankAccountsCreate.loading && bankAccountsCreate.data) {
      setModalOpen(false);
      handleOpen?.(false);
      updateTab?.();
    }
  }, [bankAccountsCreate.error, bankAccountsCreate.loading, bankAccountsCreate.data]);

  return (
    <Modal
      title={title || 'Add new bank account'}
      open={isModalOpen}
      centered
      closable
      destroyOnClose
      className="custom-modal"
      maskClosable={false}
      width={520}
      onClose={() => form?.resetFields()}
      footer={
        showFooter && (
          <div key="buttons" style={{ padding: '0 24px 24px' }}>
            {id ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  key="deleteAccount"
                  danger
                  style={{ marginRight: '5px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    // handleOpen?.(false);
                    open({
                      icon: <ExclamationCircleFilled />,
                      title: 'Delete account?',
                      content: (
                        <span>
                          Are you sure you want to delete account
                          {' '}
                          <b>{form?.getFieldValue('name')}</b>
                          ?
                        </span>
                      ),
                      cancelText: 'Cancel',
                      okText: 'Delete',
                      okButtonProps: {
                        danger: true,
                      },
                      onOk: () => {
                        bankAccountDelete.fetch(id);
                        console.log(id);
                        // setModalOpen(false);
                        handleOpen?.(false);
                        updateTab?.();
                      },
                    });
                  }}
                >
                  Delete account
                </Button>
                {contextHolder}
                <Button
                  key="update"
                  type="primary"
                  // disabled={!isValid}
                  // eslint-disable-next-line react/jsx-props-no-multi-spaces
                  onClick={() => {
                    if (form) {
                      let newData = form.getFieldsValue();

                      if (hasTransaction) {
                        const { balance, accountingStartDate, ...data } = form.getFieldsValue();

                        newData = data;
                      }

                      if (form.getFieldValue(['bank', 'phoneNumber']) === null) {
                        newData.bank.phoneNumber = '';
                        form.setFieldValue(['bank', 'phoneNumber'], '');
                      }
                      if (form.getFieldValue(['bank', 'address']) === null) {
                        newData.bank.address = '';
                        form.setFieldValue(['bank', 'address'], '');
                      }
                      if (form.getFieldValue('note') === null) {
                        newData.note = '';
                        form.setFieldValue('note', '');
                      }
                      if (form.getFieldValue('accountingStartDate')) {
                        const date = form.getFieldValue('accountingStartDate');
                        const formattedDate = date ? date.format('YYYY-MM-DD') : null;

                        newData.accountingStartDate = formattedDate;
                      }

                      form.validateFields().then(() => {
                        setInitialState(newData);
                        bankAccountUpdate.fetch(newData, id);
                      }).catch((error_) => {
                        // console.log('Validation failed:', error_);
                      });
                    }
                  }}
                >
                  Update
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  key="cancel"
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    handleOpen?.(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  key="add"
                  type="primary"
                  // disabled={!isValid}
                  // eslint-disable-next-line react/jsx-props-no-multi-spaces
                  onClick={() => {
                    if (form) {
                      form.validateFields().then((values) => {
                        const newData = form.getFieldsValue();

                        setInitialState(newData);
                        bankAccountsCreate.fetch(newData);
                      }).catch((error_) => {
                        // console.log('Validation failed:', error_);
                      });
                    }
                  }}
                >
                  Add account
                </Button>
              </div>
            )}
          </div>
        )
      }
      onCancel={() => {
        handleOpen?.(false);
      }}
    >
      <Row gutter={32}>
        <Col span={24}>
          <BankAccountForm id={id} setHasTransaction={setHasTransaction} />
        </Col>
      </Row>
    </Modal>
  );
}

export default BankAccountModal;

export function BankAccountsButton({
  id,
  title,
  updateTab,
  variant = 'button',
  text = 'Add manualy',
  modalProps,
  handleClick,
  handleCloseModal,
  isModalOpen,
  hasTransactions,
}: {
  id?: string,
  title?: string,
  updateTab?: () => void,
  text?: string,
  variant?: 'button' | 'link',
  modalProps?: BankAccounProps,
  isModalOpen: boolean,
  handleClick: () => void,
  handleCloseModal: (isOpen: boolean) => void,
  hasTransactions?: boolean,

}) {
  return (
    <>
      <Button icon={text === 'Add manualy' ? <PlusCircleOutlined /> : null} onClick={handleClick} {...(variant === 'link' ? { type: 'link', size: 'small' } : { type: 'primary' })}>
        {text}
      </Button>
      <BankAccountModal
        id={id}
        title={title}
        hasTransactions={hasTransactions}
        updateTab={updateTab}
        isModalOpen={isModalOpen}
        handleOpen={handleCloseModal}
        {...modalProps}
      />
    </>
  );
}
