import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';

const { displayName } = config;

export default function Home(): React.ReactNode | null {
  document.title = `${displayName}: Home page`;

  return (
    // disableLeftMenu={user && user.role ? user?.role === UserRoles.APPLICANT : true}
    // leftMenu={<LeftMenu />}
    <Layout>
      {/* <HomeContent /> */}
      <div style={{ textAlign: 'center' }}>Home Page</div>
    </Layout>
  );
}
