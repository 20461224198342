import React, { createContext, useState } from 'react';
import { FormInstance } from 'antd';

import { SimpleProviderProps } from '../../../../../types';
import { Transaction } from '../../../../../hooks/api/transactions';

export interface TransactionsContexttProps {
  initialState: Transaction | null,
  setInitialState: (data: Transaction) => void,
  form: FormInstance | null;
  setForm: (form: FormInstance) => void;
  isValid: boolean;
  setValid: (value: boolean) => void;
}

const defaultValue = {
  initialState: null,
  setInitialState: (data: Transaction) => {
    // default
  },
  form: null,
  setForm: () => {
    // default
  },
  isValid: false,
  setValid: (value: boolean) => {
    // default
  },
};

export const TransactionsContext = createContext<TransactionsContexttProps>(defaultValue);

function TransactionsProvider({
  children,
}: SimpleProviderProps) {
  const [initialState, setInitialState] = useState<Transaction | null>(null);
  const [form, setForm] = useState<FormInstance | null>(null);
  const [isValid, setValid] = useState<boolean>(false);

  return (
    <TransactionsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        initialState,
        setInitialState,
        form,
        setForm,
        isValid,
        setValid,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
}

export default TransactionsProvider;

export const useTransactionsContext = ():
TransactionsContexttProps => React.useContext(TransactionsContext);
