import React, { useMemo, useState } from 'react';
import {
  createSearchParams, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import {
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Menu as AntdMenu,
} from 'antd';
import { connect } from 'react-redux';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { useTranslation } from 'react-i18next';
import { Route } from '../../../routes';
import store, { RootState } from '../../../store';
import { moduleName, useAuth, User } from '../../../store/auth';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { doesUserHaveAccess } from '../../../routes/PrivateRoute';
import SignIn from '../../../pages/Auth/SignIn';
import ForgotPassword from '../../../pages/Auth/ForgotPassword';
import ResetPassword from '../../../pages/Auth/ResetPassword';
import SignUp from '../../../pages/Auth/SignUp';
import Information from '../../../pages/Auth/SignUp/Information';
import ConfirmEmail from '../../../pages/Auth/ConfirmEmail';
import Chat from '../../../pages/Chat';
import Overview from '../../../pages/Overview';
import WelcomePage from '../../../pages/Auth/SignUp/WelcomePage';
import Analysis from '../../../pages/Analysis';
import Accounts from '../../../pages/Accounts';
import Transactions from '../../../pages/Transactions';
import Documents from '../../../pages/Documents';
import Community from '../../../pages/Community';
import i18n from '../../../i18n';

import styles from './index.module.scss';

export interface MenuRoute extends Route {
  ignoreMenu?: boolean;

  children?: MenuRoute[];
}

export const routes: MenuRoute[] = [
  {
    name: 'Sign In',
    // align: 'left',
    route: {
      path: 'sign-in',
      element: <SignIn />,
    },
  },
  {
    name: 'Sign Up',
    // align: 'left',
    route: {
      path: 'sign-up',
      element: <SignUp />,
    },
    children: [
      {
        name: 'Information',
        route: {
          path: 'information',
          element: <Information />,
        },
      },
    ],
  },
  {
    name: 'Forgot password',
    // align: 'left',
    route: {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
  },
  {
    name: 'Reset password',
    // align: 'left',
    route: {
      path: 'reset-password/:secretKey',
      element: <ResetPassword />,
    },
  },
  {
    name: 'Confirm email',
    // align: 'left',
    route: {
      path: 'complete-registration/:secretKey',
      // path: 'complete-registration/:mode/:secretKey',
      element: <ConfirmEmail />,
    },
  },
  // TODO: remove after
  /* for test */
  {
    name: 'Confirm email (set a password)',
    // align: 'left',
    route: {
      path: 'complete-registration-test/password/:secretKey',
      element: <ConfirmEmail mode="password" />,
    },
  },
  /* * */
  {
    name: 'Chat',
    // align: 'left',
    route: {
      path: 'chat',
      element: <Chat />,
    },
  },
  {
    name: 'Welcome',
    // align: 'left',
    route: {
      path: 'welcome',
      element: <WelcomePage />,
    },
  },
  {
    privateRoute: true,
    name: 'header.overview',
    align: 'left',
    route: {
      path: 'overview',
      element: <Overview />,
    },
  },
  {
    privateRoute: true,
    name: 'Analysis',
    align: 'left',
    route: {
      path: 'analysis',
      element: <Analysis />,
    },
  },
  {
    privateRoute: true,
    name: 'Accounts',
    align: 'left',
    route: {
      path: 'accounts',
      element: <Accounts />,
    },
  },
  {
    privateRoute: true,
    name: 'Transactions',
    align: 'left',
    route: {
      path: 'transactions',
      element: <Transactions />,
    },
  },
  {
    privateRoute: true,
    name: 'Documents',
    align: 'left',
    route: {
      path: 'documents',
      element: <Documents />,
    },
  },
  {
    privateRoute: true,
    name: 'Community',
    align: 'left',
    route: {
      path: 'community',
      element: <Community />,
    },
  },
  {
    privateRoute: true,
    align: 'right',
    // icon: <UserOutlined />,
    icon: (
      <div className={styles.userIcon}>
        <UserOutlined style={{ color: 'white' }} />
      </div>
    ),
    name: (user) => `${user?.firstName} ${user.lastName}` || 'Default User',
    className: 'icon-padding-mini-sub',
    children: [
      {
        privateRoute: true,
        icon: <EditOutlined />,
        name: 'Edit profile',
        onClick: () => {},
      },
      {
        privateRoute: true,
        icon: <LogoutOutlined />,
        name: 'Log out',
        onClick: () => {
          store.dispatch(signOut());
        },
      },
      {
        type: 'divider',
      },
      {
        privateRoute: false,
        name: 'Accounts',
        type: 'group',
      },
      {
        privateRoute: true,
        name: 'Default User',
        onClick: () => {},
      },
    ],
  },
];

export const paramsToString = (user?: User | null, params?: ((user: User) => string) | AnyObject | string) => {
  if (!user) {
    return '';
  }

  if (typeof params === 'string') {
    return '?params';
  }

  if (typeof params === 'function') {
    let result = params(user);

    if (result) {
      if (typeof result === 'object') {
        result = createSearchParams(result).toString();
      }

      return `?${result}`;
    }

    return '';
  }

  if (params && typeof params === 'object') {
    return `?${createSearchParams(params).toString()}`;
  }

  return '';
};

// const createItem = ({
//   route, children, ignoreMenu, privateRoute, ...data
// }: Route, user: User) => {
//   let childrenList;

//   if (children && children.length > 0) {
//     childrenList = children.filter(({ ignoreMenu: childrenIgnoreMenu }) => childrenIgnoreMenu !== true);

//     if (childrenList.length > 0) {
//       childrenList = childrenList.map(
//         ({ ignoreMenu: childrenIgnoreMenu, privateRoute: childrenPrivateRoute, ...child }) => ({
//           ...child,
//           label: child.route ? (
//             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//             <NavLink to={`/${child.route.path}${paramsToString(user, child.route.path)}`}>
//               {child.name || child.key}
//             </NavLink>
//           ) : (
//             child.name
//           ),
//           key: child.route ? `/${child.route.path}` : `/${child.key || child.name}`,
//         }),
//       );
//     }
//   }

//   const name = typeof data.name === 'function' ? data.name(user) : data.name;

//   return {
//     ...data,
//     children: childrenList && childrenList.length > 0 ? childrenList : undefined,
//     label: route ? (
//       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//       <NavLink to={`/${route.path}${paramsToString(user, route.params)}`}>{name}</NavLink>
//     ) : (
//       name
//     ),
//     key: route ? `/${route.path}` : `/${data.key || name}`,
//   };
// };

const createItem = ({
  route, children, ignoreMenu, privateRoute, ...data
}: Route, user: User, t: any) => {
  let childrenList;

  if (children && children.length > 0) {
    childrenList = children.filter(({ ignoreMenu: childrenIgnoreMenu }) => childrenIgnoreMenu !== true);

    if (childrenList.length > 0) {
      childrenList = childrenList.map(
        ({ ignoreMenu: childrenIgnoreMenu, privateRoute: childrenPrivateRoute, ...child }) => ({
          ...child,
          label: child.route ? (
            <NavLink to={`/${child.route.path}${paramsToString(user, child.route.path)}`}>
              {t(child.name) || child.key}
            </NavLink>
          ) : (
            t(child.name)
          ),
          key: child.route ? `/${child.route.path}` : `/${child.key || t(child.name)}`,
        }),
      );
    }
  }

  const name = typeof data.name === 'function' ? data.name(user) : t(data.name);

  return {
    ...data,
    children: childrenList && childrenList.length > 0 ? childrenList : undefined,
    label: route ? (
      <NavLink to={`/${route.path}${paramsToString(user, route.params)}`}>{t(name)}</NavLink>
    ) : (
      t(name)
    ),
    key: route ? `/${route.path}` : `/${data.key || t(name)}`,
  };
};

export const menuLeft = routes.filter(({ ignoreMenu }) => ignoreMenu !== true).filter(({ align }) => align === 'left');

export const menuRight = routes
  .filter(({ ignoreMenu }) => ignoreMenu !== true)
  .filter(({ align }) => align === 'right');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filter(list: any[], user: User) {
  const newList = list.map((item) => ({ ...item, children: item.children ? [...item.children] : undefined }));

  return newList.filter((item) => {
    if (item && item.children && item.children.length > 0) {
      // eslint-disable-next-line no-param-reassign
      item.children = filter(item.children, user);
    }

    return item?.roles ? doesUserHaveAccess(item?.roles, user) : true;
  });
}

export interface MenuProps {
  user: User;
}

function Menu({ user }: MenuProps): React.ReactNode {
  const { t } = useTranslation();
  const { language } = i18n;
  const [languageState, setLanguageState] = useState(language);
  const navigate = useNavigate();
  const { authorized } = useAuth();
  const { open, contextHolder } = useSimpleModal();
  const { pathname } = useLocation();
  // const menuLeftFiltered = useMemo(
  //   () => filter(
  //     menuLeft.map((data) => createItem(data, user)),
  //     user,
  //   ).map((item) => ({
  //     ...item,
  //     name: typeof item.name === 'function' ? item.name(user) : item.name,
  //     icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
  //   })).map(({ children, ...item }) => item),
  //   [user],
  // );
  const handleLanguageChange = (newLanguage: 'en' | 'ge') => {
    i18n.changeLanguage(newLanguage);
    setLanguageState(newLanguage);
  };
  const menuLeftFiltered = useMemo(
    () => filter(
      menuLeft.map((data) => createItem(data, user, t)),
      user,
    ).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : t(item.name),
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    })).map(({ children, ...item }) => item),
    [user, language],
  );

  const menuRightFiltered = useMemo(() => {
    // const list = filter(
    //   menuRight.map((data) => createItem(data, user)),
    //   user,
    // ).map((item) => ({
    //   ...item,
    //   name: typeof item.name === 'function' ? item.name(user) : item.name,
    //   icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    // }));
    const list = filter(
      menuRight.map((data) => createItem(data, user, t)),
      user,
    ).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : t(item.name),
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    }));

    if (list[2] && list[2].children && list[2].children[3]) {
      list[2].children[3].onClick = () => {
        open({
          icon: <LogoutOutlined style={{ color: '#FF4D4F' }} />,
          title: 'Log out',
          content: 'Are you sure you want to Log out?',
          cancelText: 'Cancel',
          centered: true,
          okText: 'Yes',
          onOk: () => {
            store.dispatch(signOut());
            navigate('/');
          },
        });
      };
    }
    list.unshift(
      {
        privateRoute: true,
        align: 'right',
        // icon: <UserOutlined />,
        icon: (
          <div style={{ transform: 'translateX(13px)' }}>
            <img
              // src="/media/flagImgGB.png"
              src={language === 'en' ? '/media/flagImgGB.svg' : '/media/flagImgDE.svg'}
              alt="lang"
              style={{ marginTop: '16px', width: '16px' }}
            />
          </div>
        ),
        children: [
          {
            privateRoute: true,
            icon: (
              <div className={styles.langItem}>
                <img src="/media/flagImgGB.svg" alt="en" />
                <span>English</span>
              </div>
            ),
            name: 'English',
            onClick: () => { handleLanguageChange('en'); },
          },
          {
            privateRoute: true,
            icon: (
              <div className={styles.langItem}>
                <img src="/media/flagImgDE.svg" alt="ge" />
                <span>German</span>
              </div>
            ),
            name: 'German',
            onClick: () => { handleLanguageChange('ge'); },
          },
        ],
      },
    );

    return list;
  }, [user, language]);

  const activeMenuItem = `/${pathname.split('/')[1]}`;

  return (
    <div className={styles.menu}>
      <AntdMenu
        // theme="light"
        mode="horizontal"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        defaultSelectedKeys={[activeMenuItem]}
        selectedKeys={[activeMenuItem]}
        items={menuLeftFiltered}
        style={{ minWidth: 0, flex: 'auto' }}
      />
      <div style={{ justifyContent: 'end', paddingRight: 16, display: 'flex' }}>
        {authorized ? (
          <AntdMenu
            className={styles.rightMenu}
            theme="light"
            mode="horizontal"
            selectable={false}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            defaultSelectedKeys={[activeMenuItem]}
            selectedKeys={[activeMenuItem]}
            items={menuRightFiltered}
            style={{ minWidth: 0, flex: 'auto', justifyContent: 'end' }}
          />
        ) : 'not authorized'}
      </div>
      {contextHolder}
    </div>
  );
}

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(Menu);
